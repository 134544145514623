header{height: 12%; z-index: 2;width: 100%; position: fixed; transition: 0.1s ease-in-out;
	background: linear-gradient(to bottom, var(--main-dark-color) 0%, transparent 100%); 
}
.headerWhenScrolled{
	z-index: 3; backdrop-filter: blur(15px); -webkit-backdrop-filter: blur(15px); -moz-backdrop-filter: blur(15px);
}
.headerWhenSearching{ z-index: 3; background-color: var(--main-dark-color);}

header> div{ margin: 0%;}
header> .logoPart{width: 16%; margin-left: 1%; height: 100%; float: left; position: relative; }
header> .logoPart> .logoImg{height: 100%; float: left; cursor: pointer;}
header> .logoPart> .logoImg> img{height: 100%; object-fit: contain; }
header> .logoPart> .slogan{width: 50%; display: inline-block; /*float: right;*/ margin-left: 1%; }
header> .logoPart> .slogan>p{width: 100%; margin-top: 24%; font-size: 90%; color: var(--main-red-color); }
header> .logoPart> .slogan>p>span{color: var(--main-gold-color);}
header> .logoPart:after{content: ''; position: absolute; width: 2px; height: 50px; background: white; right: 1%; top: 25%;}

header> .searchPart{ margin-left: 1%; height: 35px; display: flex; 
	float: left; position: relative; margin-top:  2%; border-radius: 25px; padding-left: 5px; }
header> .searchPart> .searchIcon{width: 35px; height: 100%; border-radius: 50%; display: inline-block; cursor: pointer;
	color: white; font-size: 110%; position: relative; z-index: 2;
}
header> .searchPart> .searchIcon> img{ object-fit: contain; height: 70%; position: absolute; left: 50%; top: 50%; 
	transform: translate(-50%, -50%); pointer-events: none;}
header> .searchPart> .search{ height: 100%; display: inline-block; position: absolute; border-radius: inherit;
	outline: none; border: none; color: white; font-size: 110%; transition: 0.3s ease-in-out;
	background: rgba(230, 230, 230, 0.3); padding-left: 35px;
	backdrop-filter: blur(5px); -webkit-backdrop-filter: blur(5px); -moz-backdrop-filter: blur(5px);
}
header> .searchPart> .withInput{width: 300px;}
/*This following style overrides Material UI styles*/
/*header> .searchPart> .search > .searchFieldInput{height: 100%; outline: none;}
#searchMaterial{height: 100%; margin-top: -6%; outline: none;}
.MuiInputLabel-root{height: 10%; margin-top: -5%;  outline: none; color: var(--main-gold-color);}
.MuiOutlinedInput-root{height: 100%;  outline: none; }*/
/*This above style overrides Material UI styles*/

header> .menuPart{width: 30%; height: 100%; float: right; }
header> .menuPart> ul{display: flex; margin-top: 7%; width: 100%; }
header> .menuPart> ul> li{display: inline-block; list-style: none; margin-left: 5%; color: white; transition: 0.2s ease-in-out;}
header> .menuPart> ul> li > a{text-decoration: none; color: white; transition: 0.2s ease-in-out;}
header> .menuPart> ul> li > a.active{color: var(--main-gold-color);}
header> .menuPart> ul> li:hover{ transform: scale(1.15);}

header> .userPart{position: relative; width: 12%; height: 100%; float: right; }
header> .userPart > a > .userIcon{width: 30px; height: 30px; border-radius: 50%; display: inline-block; background-color: white; margin-top: 13%;
	margin-right: 5%; position: relative; transition: 0.2s ease-in-out;
}
header> .userPart > .active > .userIcon{color: var(--main-gold-color); transform: scale(1.15);
	background-color: var(--main-gold-color);
}
header> .userPart> a > .userIcon > img{width: 70%; height: 70%; position: absolute;left: 50%; top: 50%; transform: translate(-50%, -50%);}
header> .userPart::before{content: ''; position: absolute;; width: 2px; height: 25px; background-color: white; top: 30%; left: -10%;}
	
header.onDashboard > .logoPart{width: 27%; display: unset; }
header.onDashboard > .logoPart:after{content: none;}
header.onDashboard > .logoPart > .logoImg{width: 55%; }
header.onDashboard > .logoPart > .logoImg > img{width: 80%; margin-left: 10%; }
header.onDashboard > .logoPart > .logoImg:after{content: 'Dashboard'; position: absolute; color: var(--main-gold-color);
	text-transform: uppercase; font-size: 240%; font-stretch: ultra-condensed; left: 58%; top: 50%; transform: translate(0%, -50%);}
header.onDashboard > .logoPart > .slogan{width: 100%; height: 30px; margin-left: 5%; }
header.onDashboard > .logoPart > .slogan>p{width: 100%; margin-top: 0%; display: flex; color: var(--main-gold-color); }
header.onDashboard > .logoPart > .slogan>p>span{color: var(--main-gold-color); margin-left: 5px;}
header.onDashboard > .searchPart{display: none;}
header.onDashboard > .menuPart{ height: 85%; position: relative; margin-right: 1%; }
header.onDashboard > .menuPart:after{content: '\2630'; position: absolute; color: white; font-size: 140%; font-weight: bolder;
	left: 90%; top: 50%; transform: translate(0%, -50%); width: 40px; pointer-events: none; transition-delay: 1s;
	cursor: pointer;
}
header.onDashboard > .menuPart > ul{ visibility: hidden; }
header.onDashboard > .menuPart:hover > ul{ visibility: visible; }
header.onDashboard > .menuPart:hover::after { content: unset; }

.bottomNavigation{display: none}

.searchList{ position: absolute; z-index: 10; width: 100%; height: 88%; top: 12%; transition: 0.3s ease-in-out;
	background-color: var(--main-dark-color); 
}
#whatsAppButton{width: 50px; height: 50px; position: absolute; z-index: 2; bottom: 40px; right: 40px;border-radius: 50%; 
	overflow: hidden; box-shadow: 5px 5px 15px rgba(0, 255, 0, 0.4); background-color: rgba(0, 255, 0, 0.9);}
#whatsAppButton > img{object-fit: contain; width: 100%; height: 100%;}


@media only screen and (max-width: 600px) {
	header> .logoPart:after{display: none;}
	header> .logoPart> .slogan{display: none;}

	header> .searchPart{ margin-left: 5%; margin-top: 6%; height: 35px; }
	header> .searchPart> .searchIcon{width: 30px; margin-left: 5px; }
	header> .searchPart> .search{ display: none;  }
	header> .searchPart> .withInput{display: inline-block; width: 200px;}
	
	header> .menuPart{width: 80%; height: 30px; float: none; justify-content: center; margin: 20% 10% 0% 10%; border-radius: 25px;
		background-color: rgba(255, 255, 255, 0.2);
		backdrop-filter: blur(15px); -webkit-backdrop-filter: blur(15px); -moz-backdrop-filter: blur(15px); padding: 0% 2% 0% 2%;
	}
	header> .menuPart> ul{ width: 100%; height: 100%; margin: 0%; float: unset; padding: 0%; }
	header> .menuPart> ul> li{ width: 100%; height: 50%; margin: 1% 0% 0% 0%; font-size: 70%;}
	header> .userPart{ width: 20%; height: 100%;}
	header> .userPart::before{display: none;}
	header> .userPart > a > .userIcon{margin-top: 33%; }

	.bottomNavigation{position: fixed; bottom: 0%; width: 100%; height: 50px; background-color: var(--main-dark-color);
        z-index: 2; display: flex; justify-content: center; color: white;
    }
    .bottomNavigation > a{width: 100%; overflow: hidden;}
    .bottomNavigation > a > button{width: 100%; background-color: transparent; outline: none; border: none;
		transform: scale(0.7);}
    .bottomNavigation > a > button > img{width: 30px; height: 30px; margin: 0%; }
    .bottomNavigation > a > button > .materialIcons{width: 30px; height: 30px; margin: 0%; fill: white;}
    .bottomNavigation > a > button > p{width: 100%; color: white; margin: 0%; font-size: 90%;}
    .bottomNavigation > button{width: 100%; background-color: transparent; outline: none; border: none;
		transform: scale(0.7);
	}
    .bottomNavigation > button > img{width: 30px; height: 30px; margin: 0%; }
    .bottomNavigation > button > .materialIcons{width: 30px; height: 30px; margin: 0%; fill: white;}
    .bottomNavigation > button > p{width: 100%; color: white; margin: 0%; font-size: 90%;}
	.bottomNavigation > a.active > button > p{color: var(--main-gold-color);}
	.bottomNavigation > a.active > button > .materialIcons{fill: var(--main-gold-color); }
	header.onDashboard > .logoPart{width: 50%}
	header.onDashboard > .logoPart > .logoImg:after{ font-size: 100%; padding-top: 4%;}
	header.onDashboard > .menuPart{ height: 50%;}

	#whatsAppButton{width: 40px; height: 40px; position: absolute; z-index: 2; bottom: 60px; right: 10px;}


}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 5 &SE*/
	header> .searchPart{ margin-left: 3%; margin-top: 6%; height: 30px; }
	header> .searchPart> .searchIcon{width: 30px; margin-left: 5px; }
	header> .searchPart> .search{ display: none;  }
	header> .searchPart> .withInput{ width: 170px; display: inline-block;}
	header> .menuPart{ margin: 20% 5% 0% 10%;}
	header> .menuPart> ul> li{ width: 100%; height: 50%; margin: 1% 0% 0% 0%; font-size: 65%;}
	header> .userPart > a > .userIcon{margin-top: 30%; width: 24px; height: 24px; }

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
	/*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
	/*iPhone X*/
	header{height: 10%;}

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	/*iPad Pad 1, 2, Mini and Air*/
	

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
	/*iPad*/
	header{height: 8%}
	header> .logoPart:after{display: none;}
	header> .logoPart> .slogan{display: none;}

	header> .searchPart{ margin-left: 5%; margin-top: 3%; height: 35px; }
	header> .searchPart> .searchIcon{width: 30px; margin-left: 5px; }
	header> .searchPart> .search{ display: none;  }
	header> .searchPart> .withInput{display: inline-block; width: 200px;}
	
	header> .menuPart{width: 65%; height: 35px; float: none; justify-content: center; margin: 10% 17.5% 0% 17.5%; border-radius: 25px;
		background-color: rgba(255, 255, 255, 0.2);
		backdrop-filter: blur(15px); -webkit-backdrop-filter: blur(15px); -moz-backdrop-filter: blur(15px); padding: 0% 2% 0% 2%;
	}
	header> .menuPart> ul{ width: 100%; height: 100%; margin: 0%; float: unset; padding: 0%; }
	header> .menuPart> ul> li{ width: 100%; height: 50%; margin: 1% 0% 0% 0%; font-size: 80%;}
	header> .userPart{ width: 20%; height: 100%;}
	header> .userPart::before{display: none;}
	header> .userPart > a > .userIcon{margin-top: 15%;}

	header.onDashboard > .logoPart{width: 40%}
	header.onDashboard > .logoPart > .logoImg:after{ font-size: 150%; padding-top: 4%;}
	header.onDashboard > .menuPart{ height: 50%;}
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
	/*iPad Pro*/
	header{height: 8%;}
	header> .logoPart> .slogan{display: none;}
	header> .searchPart{  height: 35px;  margin-top:  3%;}
	header> .searchPart> .withInput{width: 250px;}
	header> .menuPart{width: 40%; margin-right: 4%; margin-top: 1%;}
	header> .userPart{width: 11%; margin-right: 1%;}
	header> .userPart > a > .userIcon{width: 35px; height: 35px; margin-top: 22%; margin-right: 3%;}
	header.onDashboard > .logoPart{width: 40%}
	header.onDashboard > .logoPart > .logoImg:after{ font-size: 170%; padding-top: 4%;}
	header.onDashboard > .menuPart{ margin-right: 3%;}
}

 @media only screen and (device-width: 1280px)and (device-height:800px)and (-webkit-device-pixel-ratio:1){
	/*laptop with mdpi screen*/
	header> .menuPart{width: 36%; margin-right: 2%;}
	header> .userPart > a > .userIcon{ margin-top: 15%;}
  }
  @media only screen and (device-width: 1280px)and (device-height:950px)and (-webkit-device-pixel-ratio:1){
	/*laptop with touch screen*/
	header> .logoPart{width: 20%;}
	header> .menuPart{width: 36%; margin-right: 2%;}
	header> .userPart > a > .userIcon{ margin-top: 17%;}
  }
  
  @media only screen and (device-width: 1366px)and (device-height:768px){
	/*HP laptop screen*/
	header> .menuPart{width: 34%; margin-right: 2%;}
  }
  
  @media only screen and (device-width: 1440px)and (device-height:900px)and (-webkit-device-pixel-ratio:2){
	/*laptop with hidpi screen*/
	header> .menuPart{width: 32%; margin-right: 2%;}
 }


/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-height: 600px) and (orientation: landscape) {
	header> .logoPart:after{display: none;}
	header> .logoPart> .slogan{display: none;}

	header> .searchPart{ margin-left: 5%; margin-top: 1%; height: 35px; }
	header> .searchPart> .searchIcon{width: 30px; margin-left: 5px; }
	header> .searchPart> .search{ display: none;  }
	header> .searchPart> .withInput{display: inline-block; width: 200px;}
	
	header> .menuPart{width: 80%; height: 30px; float: none; justify-content: center; margin: 7% 10% 0% 10%; border-radius: 25px;
		background-color: rgba(255, 255, 255, 0.2);
		backdrop-filter: blur(15px); -webkit-backdrop-filter: blur(15px); -moz-backdrop-filter: blur(15px); padding: 0% 2% 0% 2%;
	}
	header> .menuPart> ul{ width: 100%; height: 100%; margin: 0%; float: unset; padding: 0%; }
	header> .menuPart> ul> li{ width: 100%; height: 50%; margin: 1% 0% 0% 0%; font-size: 80%;}
	header> .userPart{ width: 20%; height: 100%;}
	header> .userPart::before{display: none;}
	header> .userPart > a > .userIcon{margin-top: 7%; }
	header.onDashboard > .logoPart > .logoImg:after{ font-size: 100%; padding-top: 4%;}
	header.onDashboard > .logoPart{width: 30%}


}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1366px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad Pro*/
	header> .logoPart{width: 20%}
	header> .logoPart> .slogan{display: none;}
	header> .menuPart{width: 40%; margin-right: 4%;}
	header> .userPart > a > .userIcon{width: 35px; height: 35px; margin-top: 20%;}
	header.onDashboard > .logoPart > .logoImg:after{ font-size: 170%; padding-top: 4%;}

}

@media only screen and (device-width: 1024px)and (device-height:768px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad*/
	/* header{border: 1px solid red;} */
	/* header> .logoPart:after{display: none;} */
	header> .logoPart> .slogan{display: none;}

	header> .menuPart{width: 45%}
	header> .userPart{position: relative; width: 12%; height: 100%; float: right; }
	header> .userPart > a > .userIcon{width: 30px; height: 30px; margin-top: 20%; margin-right: 5%;}
	header.onDashboard > .logoPart > .logoImg:after{ font-size: 130%; padding-top: 4%;}
	
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
	/*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
	/*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
	/*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 5 &SE*/

}