.logContainer{height: 100%; width: 100%; position: fixed; z-index: 100; background-image: url("../../images/login cover.JPG");
	background-repeat: no-repeat, no-repeat; /*opacity:0.6;*/ -webkit-background-size: cover;
    -moz-background-size: cover; -o-background-size: cover; background-size: cover; display: block;
}
/* .logContainer:before{content: ''; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); position: absolute;} */
.logContainer > #closeLogin{width: 34px; height: 34px; border-radius: 50%; font-size: 140%; margin: 2%; border: none; cursor: pointer;
	position: absolute; z-index: 100; transform: rotateY(180deg); background-color: rgba(255, 255, 255, 0.9); color: black;
	backdrop-filter: blur(15px); -webkit-backdrop-filter: blur(15px); -moz-backdrop-filter: blur(15px)}

.logContainer> .elementContainer{position: absolute; left: 50%; top: 45%; transform: translate(-50%,-50%);
  width: 30%; min-height: 50vh; transition: 0.3s ease-in-out; }
.logContainer> .elementContainer> .montLogo{width: 100%; }
.logContainer> .elementContainer> .montLogo> .myCenter> .logo{width: 200px; }
.logContainer> .elementContainer> .montLogo> .myCenter> .logo>img{width: 100%; object-fit: contain;}
.logContainer> .elementContainer> .montLogo> .myCenter> p{color: var(--main-gold-color); margin-top: 0%;}
.logContainer> .elementContainer> .landing{ width: 100%; min-height: 30vh; border: 1px solid var(--main-gold-color); 
	border-radius: 25px ; backdrop-filter: blur(15px); -webkit-backdrop-filter: blur(15px); -moz-backdrop-filter: blur(15px)}
.logContainer> .elementContainer> .landing> h3{text-align: center; color: white;}
.logContainer> .elementContainer> .landing> form{margin-top: 3%; width: 100%; border-radius: inherit}
.logContainer> .elementContainer> .landing> form> .inputCont{margin-top: 2%; width: 80%; margin-left: 10%; height: 40px;
	background: white; border-radius: inherit; display: flex;}
.logContainer> .elementContainer> .landing> form> .inputCont> div{border-radius: inherit; width: 11%; height: 100%;
	overflow: hidden; position: relative;}
.logContainer> .elementContainer> .landing> form> .inputCont> div > img{border-radius: inherit; width: 100%; object-fit: contain;
	position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);
}
.logContainer> .elementContainer> .landing> form> .inputCont> input{border-radius: inherit;border: none; width: 88%; 
	height: 90%; color: black; outline: none; }
.logContainer> .elementContainer> .landing> form> .inputCont> .otp{text-align: center; font-size: 150%; width: 100%;}
.logContainer> .elementContainer> .landing> form>.logwithContainer{border-radius: inherit; width: 60%; margin-left: 10%; margin-top: 4%;
	display: none/*flex*/; font-size: 90%;
}
.logContainer> .elementContainer> .landing> form>.logwithContainer > p{width: 40%; display: inline-block; text-align: right; 
	padding-right: 5%; color: white; }
.logContainer> .elementContainer> .landing> form>.logwithContainer > .logWithCenter{width: 45%; border-radius: inherit;
	display: flex; justify-content: center;
}
.logContainer> .elementContainer> .landing> form>.logwithContainer > .logWithCenter > .socialIconLogin{width: 40px; height: 40px;
	border-radius: inherit; background: white; margin: 0% 1% 0% 1%;}
	.logContainer> .elementContainer> .landing> form>.logwithContainer > .logWithCenter > .socialIconLogin > img{width: 100%; height: 100%;}
.logContainer> .elementContainer> .landing> form> button{width: 60%; height: 40px; background: transparent; color: var(--main-gold-color);
	border: 1px solid var(--main-gold-color); border-radius: inherit; margin-left: 20%; margin-top: 8%; cursor: pointer;}
.logContainer> .elementContainer> .landing> form> p{width: 100%; text-align: center; color: var(--main-gold-color); margin-top: 5%;}
.logContainer> .elementContainer> .landing> form> p > a, p{color: var(--main-gold-color); text-decoration: none; cursor: pointer;}
.logContainer> .elementContainer> .landing> form> p > a> span{color: white;}


@media only screen and (max-width: 600px) {
	.logContainer> .elementContainer{width: 99%;}
	.logContainer> .elementContainer> .landing{ border: none; }

}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
	/*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
	/*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	/*iPad Pad 1, 2, Mini and Air*/
	

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
	/*iPad*/
	.logContainer> .elementContainer{width: 50%;}
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
	/*iPad Pro*/
	.logContainer> .elementContainer{width: 50%;}
}



/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-height: 600px) and (orientation: landscape) {
	.logContainer> .elementContainer{width: 40%}
	.logContainer> .elementContainer> .landing{ border: none; }
	.logContainer> .elementContainer> .landing> form> .inputCont{height: 30px;}
	.logContainer> .elementContainer> .landing> form>.logwithContainer > .logWithCenter > .socialIconLogin{width: 25px; height: 25px;
		 margin: 10% 2% 0% 2%;}
	.logContainer> .elementContainer> .landing> form> button{width: 60%; height: 33px;}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1024px)and (device-height:1366px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad Pro*/

}

@media only screen and (device-width: 768px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad*/
	
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
	/*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
	/*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
	/*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 5 &SE*/

}