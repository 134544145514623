.theEpisode{ display: flex; background-color: rgb(235, 235, 235); border-radius: 15px;
    width: 100%; height: 75px; padding: 1%; margin-top: 2%;
}

.theEpisode > .thumbnail{ width: 20%; height: 99%; border-radius: inherit; background-color: rgb(215, 215, 215);
    position: relative;
}
.theEpisode > .thumbnail > div{ width: 100%; height: 100%; border-radius: inherit; }
.theEpisode > .thumbnail > div > img{ width: 100%; height: 100%; border-radius: inherit; object-fit: cover; cursor: pointer;}
.theEpisode > .moreInfo{  width: 80%; border-radius: inherit; display: flex; }
.theEpisode > .moreInfo > div:nth-child(1){ border-radius: inherit; width: 88%; padding: 1%; overflow: hidden;}
.theEpisode > .moreInfo > div > p{border-radius: inherit; margin: 5% 0% 0% 2%; color: black; position: relative;
	height: 20px; font-size: 90%;
}
.theEpisode > .moreInfo > div:nth-child(1) > .title{ width: 90%; margin: 0%; font-weight: bolder;}
.theEpisode > .moreInfo > div:nth-child(1) > .description{ width: 90%; height: 40px; margin: 0%; margin-top: -2%;
	color: rgb(39, 39, 39);
}
.theEpisode > .moreInfo > div:last-child{ border-radius: inherit; width: 10%; display: flex; flex-direction: column;
    justify-content: center;}
.theEpisode > .moreInfo > div:last-child > p{ color: rgb(87, 86, 86);
	border-radius: inherit; margin: 2%; font-weight: bolder; font-size: 70%;}

@media only screen and (max-width: 600px) {
    .theEpisode > .thumbnail{ width: 35%;}
    .theEpisode > .moreInfo{  width: 65%; }
    .theEpisode > .moreInfo > div:nth-child(1) > .description{ margin-top: -5%;}

}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
	/*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
	/*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	/*iPad Pad 1, 2, Mini and Air*/
	

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
	/*iPad*/
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
	/*iPad Pro*/
}



/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (orientation: landscape) {
	
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1024px)and (device-height:1366px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad Pro*/

}

@media only screen and (device-width: 768px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad*/
	
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
	/*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
	/*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
	/*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 5 &SE*/

}