.landing{width: 100%; height: 100%; border-radius: inherit; }

.landing > .upperCard{border: 1px solid red; margin: 0%;}
.landing > .lowerCard{ margin: 0%;}
.landing > .lowerCard > .allUsers{margin: 4% 0% 0% 0%; }
.landing > .lowerCard > .allUsers > h1{margin: 0%; padding-left: 2%;}
.landing > .lowerCard > .allUsers > .regGroups{width: 96%; height: 40vh; margin: 2%; border-radius: 15px; /*background-color: white*/
    }
.landing > .lowerCard > .allUsers > .regGroups > .userCounts{ width: 150px; height: 90px; display: inline-block; margin: 3%;
    cursor: pointer;
}
.landing > .lowerCard > .allUsers > .regGroups > .userCounts > div{ width: 100%; height: 90%; border: 1px solid silver;
    border-radius: 15px; position: relative;}
.landing > .lowerCard > .allUsers > .regGroups > .userCounts > div > p{font-size: 250%; margin: 0%;
    position: absolute; left: 50%; top: 50%;  transform: translate(-50%, -50%);  }
.landing > .lowerCard > .allUsers > .regGroups > .userCounts > p{ width: 100%; height: 10%; font-size: 90%; text-align: center;
    color: var(--main-gold-color); margin: 0%;}

    @media only screen and (max-width: 600px) {
        .landing > .lowerCard > .allUsers > h1{font-size: 22px;}
        .landing > .lowerCard > .allUsers > .regGroups > .userCounts{ width: 43%; height: 90px;}

    }
    @media only screen and (min-width: 600px) and (max-width: 1023px) {
    
    }
    @media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
        /*iPhone 5 &SE*/
    
    }
    @media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
        /*iPhone 6, 7, 8*/
    
    }
    @media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
        /*iPhone 6+, 7+, 8+*/
    
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
        /*iPhone X*/
    
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
        /*iPad Pad 1, 2, Mini and Air*/
        
    
    }
    
    @media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
        /*iPad*/
        
    }
    
    @media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
        /*iPad Pro*/
        
    }
    
    
    
    /*
    *=============================================================================================================
    *LANDSCAPE EDITION
    */
    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-height: 600px) and (orientation: landscape) {
        .landing > .lowerCard > .allUsers > h1{font-size: 22px;}
        .landing > .lowerCard > .allUsers > .regGroups > .userCounts{ width: 27%; height: 90px;}
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
        
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (orientation: landscape) {
    
    }
    
    @media only screen and (device-width: 1024px)and (device-height:1366px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
        /*iPad Pro*/
    
    }
    
    @media only screen and (device-width: 768px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
        /*iPad*/
        
    }
    
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
        /*iPad Pad 1, 2, Mini and Air*/
    
    }
    
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
        /*iPhone X*/
    
    }
    
    @media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
        /*iPhone 6+, 7+, 8+*/
    
    }
    
    @media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
        /*iPhone 6, 7, 8*/
    
    }
    
    @media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
        /*iPhone 5 &SE*/
    
    }
    