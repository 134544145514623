.dashboardBody{position: relative; background-color: white; width: 96%; height: 78vh; margin: 10% 2% 2% 2%; border-radius: 15px;}
.hideDashboard{backdrop-filter: blur(15px); -webkit-backdrop-filter: blur(15px); -moz-backdrop-filter: blur(15px); 
	filter: blur(15px); -webkit-filter: blur(15px); -moz-filter: blur(15px); -ms-filter: blur(15px); -o-filter: blur(15px); 
	/* transition: 0.5s ease-out; */
}

.dashboardBody > .navigations{position: absolute; width: 20%; height: 100%; left: 0%; border-radius: 15px 0px 0px 15px; }
.dashboardBody > .mainView{position: absolute; width: 80%; height: 100%; right: 0%; border-radius: 0px 15px 15px 0px; 
	border-left: 1px silver; background-color: rgb(235, 235, 235); overflow: hidden;}

.dashboardBody > .navigations > .currentUser{width: 100%; margin-top: 5%; margin-bottom: 15%;}
.dashboardBody > .navigations > .currentUser > .profilePic{width: 100px; height: 100px; border-radius: 50%; overflow: hidden;
	margin: auto; border: 1px solid silver;}
.dashboardBody > .navigations > .currentUser > .roleName{color: silver; width: 100%; text-align: center;}
.dashboardBody > .navigations > .currentUser > .userNames{color: black; width: 100%; text-align: center; font-weight: bolder;}
.dashboardBody > .navigations > a{width: 96%; height: 50px; text-decoration: none; margin: 5% 0% 0% 2%; 
	display: flex; overflow: hidden; border-radius: 50px; transition: 0.1s ease-in-out;
}
.dashboardBody > .navigations > a > div{width: 20%; height: 100%; }
.dashboardBody > .navigations > a > div > .icon{height: 100%; object-fit: contain; color: gray; margin-left: 10px; }
.dashboardBody > .navigations > a > p{width: 80%; height: 100%; color: black; margin: 0%; text-indent: 0%;  display: flex;
	flex-direction: column; justify-content: center;}
.dashboardBody > .navigations > a:hover{box-shadow: 0px 0px 15px silver;}
.dashboardBody > .navigations > a.active{background-color: rgb(235, 235, 235);  }
.dashboardBody > .navigations > a.active > div > .icon{color: var(--main-gold-color); }
/* .dashboardBody > .navigations > a.active > p{color: var(--main-gold-color); } */

.dashboardBody > .mainView > .users{width: 100%; height: 100%; border-radius: inherit;}
.dashboardBody > .mainView > .subscriptions{width: 100%; height: 100%; border-radius: inherit}
.dashboardBody > .mainView > .content{width: 100%; height: 100%; border-radius: inherit}

@media only screen and (max-width: 600px) {
	.dashboardBody{position: unset; width: 98%; height: 75vh; margin: 40% 1% 1% 1%;}
	.dashboardBody > .navigations{position: unset; width: 100%; height: 20%; white-space: nowrap; overflow-x: auto;}
	.dashboardBody > .mainView{position: relative; width: 100%; height: 80%; border-radius: 0px 15px 15px 0px; overflow:auto;}

	.dashboardBody > .navigations > .currentUser{width: 50%; margin: 0%; display: none; border: 5px solid red;}
	.dashboardBody > .navigations > .currentUser > .profilePic{display: none;}
	/* .dashboardBody > .navigations > .currentUser > .roleName{color: silver; width: 100%; text-align: center;} */
	.dashboardBody > .navigations > .currentUser > .userNames{margin-top: -3%; display: inline-block;}
	.dashboardBody > .navigations > a{ margin: 5% 3% 0% 0%; display: inline-flex; width: 40%; background-color: rgb(247, 241, 241);}
	.dashboardBody > .navigations > a.active{background-color: rgb(230, 230, 230);  }
	.dashboardBody > .navigations > a > div{width: 30%; height: 100%; display: inline-block;}
	.dashboardBody > .navigations > a > div > .icon{height: 100%; object-fit: contain; color: gray; margin-left: 10px; }
	.dashboardBody > .navigations > a > p{width: 60%; display: flex; height: 100%; color: black; margin: 0%;}
}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
	/*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
	/*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	/*iPad Pad 1, 2, Mini and Air*/
	

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
	/*iPad*/
	.dashboardBody{width: 98%; height: 65vh; margin: 21% 1% 1% 1%;}
	.dashboardBody > .navigations > a > p{ margin-left: 6%;}

	
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
	/*iPad Pro*/
	.dashboardBody{height: 65vh; margin: 16% 2% 2% 2%;}
}



/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-height: 600px) and (orientation: landscape) {
	.dashboardBody{height: 110vh; margin: 15% 1% 1% 1%;}
	.dashboardBody > .navigations > a > p{ margin-left: 6%;}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1024px)and (device-height:1366px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad Pro*/

}

@media only screen and (device-width: 768px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad*/
	
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
	/*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
	/*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
	/*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 5 &SE*/

}
