.landing{width: 100%; height: 100%; border-radius: inherit; }

.landing > .upperCard{border: 1px solid red; margin: 0%;}
.landing > .lowerCard{ margin: 0%;}
.landing > .lowerCard > .userActivity{margin: 4% 0% 0% 0%; }
.landing > .lowerCard > .userActivity > h1{margin: 0%; padding-left: 2%;}
.landing > .lowerCard > .userActivity > .plots{width: 96%; height: 40vh; margin: 2%; border-radius: 15px; /*background-color: white*/
    }
.landing > .lowerCard > .userActivity > .plots > div{ height: 80%; margin-top: 2%; border-radius: inherit; display: inline-block;
    /*background-color: white;*/ border: 1px solid silver;}
.landing > .lowerCard > .userActivity > .plots > .graph{width: 50%;}
.landing > .lowerCard > .userActivity > .plots > .pieMembership{width: 20%; margin-left: 4.5%;}
.landing > .lowerCard > .userActivity > .plots > .pieContentType{width: 20%; margin-left: 4.5%;}
.landing > .lowerCard > .performanceMetrics{margin: 0%; }
.landing > .lowerCard > .performanceMetrics > h1{margin: 0%; padding-left: 2%;}
.landing > .lowerCard > .performanceMetrics > .kpis{width: 96%; height: 60px; display: flex; justify-content: center; 
    margin: 0% 2% 0% 2%; border-radius: 15px; border: 1px solid silver; /*background-color: white;*/}
.landing > .lowerCard > .performanceMetrics > .kpis > div{width: 100%; position: relative;}
.landing > .lowerCard > .performanceMetrics > .kpis > div:after{position: absolute; content: ''; height: 60%; width: 1px; 
    background-color: silver; right: 0%; top: 15%;}
.landing > .lowerCard > .performanceMetrics > .kpis > div:last-child:after{display: none;}
.landing > .lowerCard > .performanceMetrics > .kpis > div > p{width: 100%; margin: 0%; font-size: 80%; text-align: center;}
.landing > .lowerCard > .performanceMetrics > .kpis > div > p:nth-child(1){margin-top: 2%;}
.landing > .lowerCard > .performanceMetrics > .kpis > div > p:nth-child(2){text-align: center; font-size: 200%; font-weight: bold;}



@media only screen and (max-width: 600px) {
    .landing > .lowerCard > .userActivity > .plots > div{ height: 50%;}
    .landing > .lowerCard > .userActivity > .plots > .graph{width: 50%;}
    .landing > .lowerCard > .userActivity > .plots > .pieMembership{width: 40%; margin-left: 4.5%;}
    .landing > .lowerCard > .userActivity > .plots > .pieContentType{width: 40%; margin-left: 4.5%;}
}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
    /*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
    /*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
    /*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
    /*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    /*iPad Pad 1, 2, Mini and Air*/
    

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
    /*iPad*/
    .landing > .lowerCard > .userActivity > .plots > div{ height: 45%;}
    /* .landing > .lowerCard > .userActivity > .plots > .graph{width: 50%;} */
    .landing > .lowerCard > .userActivity > .plots > .pieMembership{width: 40%; margin-left: 4.5%;}
    .landing > .lowerCard > .userActivity > .plots > .pieContentType{width: 40%; margin-left: 4.5%;}
    
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
    /*iPad Pro*/
    .landing > .lowerCard > .userActivity > .plots > div{ height: 45%;}
    /* .landing > .lowerCard > .userActivity > .plots > .graph{width: 50%;} */
    .landing > .lowerCard > .userActivity > .plots > .pieMembership{width: 40%; margin-left: 4.5%;}
    .landing > .lowerCard > .userActivity > .plots > .pieContentType{width: 40%; margin-left: 4.5%;}
    
}



/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-height: 600px) and (orientation: landscape) {
    .landing > .lowerCard > .userActivity > h1{font-size: 20px;}
    .landing > .lowerCard > .performanceMetrics > h1{font-size: 20px;}
    .landing > .lowerCard > .userActivity > .plots > div{ height: 50%;}
    .landing > .lowerCard > .userActivity > .plots > .graph{width: 50%;}
    .landing > .lowerCard > .userActivity > .plots > .pieMembership{width: 40%; margin-left: 4.5%;}
    .landing > .lowerCard > .userActivity > .plots > .pieContentType{width: 40%; margin-left: 4.5%;}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1024px)and (device-height:1366px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
    /*iPad Pro*/

}

@media only screen and (device-width: 768px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
    /*iPad*/
    
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
    /*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
    /*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
    /*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
    /*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
    /*iPhone 5 &SE*/

}