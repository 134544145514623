.userCard{width: 283px; border-radius: 15px; box-shadow: 0px 0px 10px silver; transition: 0.3s ease-in-out; cursor: pointer;
    background-color: var(--main-dark-color); color: var(--main-gold-color); margin: 2%; padding: 2%; display: inline-block;}
.userCard:hover{transform: scale(1.1); box-shadow: 0px 0px 25px silver; }
.voucherCardCancelled{ box-shadow: 0px 0px 10px silver; background: rosybrown;}
.voucherCardExpired{ box-shadow: 0px 0px 10px silver; background-color: red;}
.voucherCardDone{background: rgb(58, 71, 37); box-shadow: 0px 0px 10px silver; }
.voucherCardInProgress{ box-shadow: 0px 0px 10px silver; background-color: green;}
.voucherCardDone{ box-shadow: 0px 0px 10px silver; }

.userCard > .profilePicHolder{width: 50px; height: 50px; border: 3px solid white; border-radius: 50%; overflow: hidden; margin: 2%}
.userCard > .profilePicHolder > img{width: 100%; height: 100%;object-fit: cover; border-radius: inherit;}
.userCard > .amount{width: 96%; margin-top: 0%; font-weight: bolder; text-align: center;}
.userCard > .description{width: 96%; margin: 0%; color: white;}
.userCard > .theDates{width: 96%; display: flex; margin: 0%; color: white;}
.userCard > .theDates > p{width: 100%; margin: 0%; color: white; font-size: 80%;}