.previewBackground{width: 100%; height: 100%; position: fixed; z-index: 10; top: 0%; left: 0%; 
    background-color: rgba(0, 0, 0, 0.9);
}
.videoPreview{ overflow: hidden; display: none; transition: 0.3s ease-in-out;
    position: fixed; z-index: 11; width: 45%; top: 50%; left: 50%; transform: translate(-50%, -50%); min-height: 200px; 
	border-radius: 15px; background-color: white; box-shadow: 0px 0px 15px black; max-height: 90vh; transition-delay: 2s;
}
.showVideoPreview{display: block; }

.videoPreview > .coverSection{position: relative; width: 100%; min-height: 250px; border-radius: 15px 15px 0px 0px; }
.videoPreview > .coverSection > .thumbnail{width: 100%; height: 200px; border-radius: inherit; overflow: hidden; cursor: pointer; }
.videoPreview > .coverSection > .thumbnail > img{width: 100%; height: 100%; object-fit: cover;}
.videoPreview > .coverSection > h1{position: absolute; top: 120px; height: 40px; padding: 2%; text-transform: uppercase;
	text-shadow: 0px 0px 1px rgba(255, 255, 255, 0.7);
}
.videoPreview > .coverSection > p{width: 96%; min-height: 30px; max-height: 70px; padding: 2%; margin: 0%;
	overflow: hidden; 
	/* white-space: nowrap; */
	text-overflow: ellipsis;}
.videoPreview > .videoListPart{width: 96%; max-height: 300px; margin: 0%; padding: 2% 2% 0% 2%; border-radius: inherit;
	display: flex; flex-direction: column;
}
.videoPreview > .videoListPart > .chooseSeason{width: 99%; height: 40px; }
.videoPreview > .videoListPart > .chooseSeason > label{height: 100%; font-weight: bolder; font-size: 130%; padding: 4% 0% 0% 0%}
.videoPreview > .videoListPart > .chooseSeason > select{width: 40%; height: 100%; border-radius: 10px; border: none; font-size: 110%;
    float: right; background-color: var(--main-dark-color); color: var(--main-gold-color);}
/* .videoPreview > .videoListPart > .chooseSeason > select > .selectOptions{} */
.videoPreview > .videoListPart > .episodes{width: 98%; height: 100%; margin-top: 2%; padding-right: 2%;
	overflow: hidden; overflow-y: auto;
}


.videoPreview > .videoListPart > .episodes::-webkit-scrollbar {width: 2px; height: 2px;border-radius: 5px;}



@media only screen and (max-width: 600px) {
    .videoPreview{ width: 98%; }
	

}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
	/*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
	/*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	/*iPad Pad 1, 2, Mini and Air*/
	

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
	/*iPad*/
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
	/*iPad Pro*/
}



/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (orientation: landscape) {
	
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1024px)and (device-height:1366px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad Pro*/

}

@media only screen and (device-width: 768px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad*/
	
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
	/*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
	/*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
	/*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 5 &SE*/

}


@keyframes videoPreviewSlideInBottom {
	0% { opacity:0; -webkit-transform: translate(-50%, -30%); }
	/* 80% { opacity:0.8; -webkit-transform: translate(-50%, -30%); } */
	100% { opacity:1; -webkit-transform: translate(-50%, -50%); }
} 