.voucherCard{width: 280px; border-radius: 15px; box-shadow: 0px 0px 10px silver; transition: 0.3s ease-in-out; cursor: pointer;
    background-color: var(--main-dark-color); color: var(--main-gold-color); margin: 2%; padding: 2%; display: inline-block;}
.voucherCard:hover{transform: scale(1.1); box-shadow: 0px 0px 25px silver; }
.voucherCardCancelled{ box-shadow: 0px 0px 10px silver; background: rosybrown;}
.voucherCardExpired{ box-shadow: 0px 0px 10px silver; background-color: red;}
.voucherCardDone{background: rgb(58, 71, 37); box-shadow: 0px 0px 10px silver; }
.voucherCardInProgress{ box-shadow: 0px 0px 10px silver; background-color: green;}
.voucherCardDone{ box-shadow: 0px 0px 10px silver; }
.voucherCard > .amount{width: 96%; margin-top: 0%; font-weight: bolder; text-align: center;}
.voucherCard > .description{width: 96%; margin: 0%; color: white;}
.voucherCard > .theDates{width: 96%; display: flex; margin: 0%; color: white;}
.voucherCard > .theDates > p{width: 100%; margin: 0%; color: white; font-size: 80%;}