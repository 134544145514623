.videoPlayer{width: 1024px/*898px*//*720*/; height: 576px/*505px*//*420*/; max-width: 100%;
    top: 35%; left: 50%; transform: translate(-50%, -40%); position: relative; z-index: 13; transition: 0.3s ease-in-out; 
}
.videoPlayer > #videoCover{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    z-index: 1;
    background-color: transparent;
    /* pointer-events: none; */
}
.fullscreen{top: 0%; left: 0%; width: 100%; height: 100%; }
.videoPlayer > #videoPlayerLoader{position: absolute; z-index: 2; width: 100%; height: 85%; top: 0%; transition: 0.3s ease-in-out;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, transparent 100%); display: none;
}
.videoPlayer > #videoPlayerLoader > div{width: 100%; height: 100%; position: relative; }
.videoPlayer > #videoPlayerLoader > div > div{ left: 50%; top: 50%; transform: translate(-50%, -50%); }
.videoPlayer > .playerDescription{width: 100%; height: 100%; position: absolute; top: 0%; transition: 0.3s ease-in-out;
    cursor: pointer; background-color: rgba(0, 0, 0, 0.7); display: none; 
}
.videoPlayer > .playerDescription > p{width: 100%; font-size: 120%; color: rgba(255,255,255, 0.7); text-align: center;}
.videoPlayer:hover > .playerDescription{background-color: rgba(0, 0, 0, 0.5); }
.videoPlayer > .playerDescription > div.pausedOverlay{position: absolute; top: 50%; left: 2%; transform: translate(0%, -50%); 
    border-radius: 10px; width: 60%; min-height: 100px; background-color: rgba(0, 0, 0, 0.6); padding: 5px;
    backdrop-filter: blur(15px); -webkit-backdrop-filter: blur(15px); -moz-backdrop-filter: blur(15px);
}
.videoPlayer > .playerDescription > div > p:nth-child(1){font-size: 150%; font-weight: bolder; text-transform: uppercase;}

.videoPlayer > .playerVideo{width: 100%; height: 100%; position: relative;}

.videoPlayer > .playerControls{ 
    position: absolute; bottom: 0%; width: 100%; height: 64px; margin: 0%; padding-top: 5px;
    background: linear-gradient(to top, var(--main-dark-color) 0%, transparent 100%); padding: 0%; 
    z-index: 2;
}
.videoPlayer > .playerControls > .playerProgress{ width: 100%; height: 15px; margin: 0%; display: flex; justify-content: center; }
.videoPlayer > .playerControls > .playerProgress > .vidProgress{width: 90%; height: 3px; margin: 0.5% 1% 1% 0%;
    background-color: rgba(255, 255, 255, 0.3); position: relative; cursor: pointer; 
}
.videoPlayer > .playerControls > .playerProgress > .vidProgress > .progressBar{width: 30%; height: 100%; display: none;
    position: absolute; top: 50%; transform: translateY(-50%); background-color: rgba(255, 255, 255, 0.3);}
.videoPlayer > .playerControls > .playerProgress > .vidProgress > .seekSlider{width: 100%; height: 100%; cursor: pointer;
    position: absolute; top: 0%; transform: translateY(-50%); background-color: var(--main-gold-color);
}
input[type=range]::-webkit-slider-thumb{background: #cfaa1d}
input[type=range]::-webkit-slider-runnable-track{background: transparent}
.videoPlayer > .playerControls > .playerProgress > .playerTime{width: 10%; height: 100%; color: white; font-size: 80%;
    display: flex; justify-content: center; padding-right: 1%; text-align: center; 
}
.playerTime > span{width: 100%; display: flex; flex-direction: column-reverse; justify-content: center;}
.playerTime > #currentTimeText{padding: 0% 3% 0% 0%;}
.playerTime > #durationTimeText{padding: 0% 0% 0% 3%; }
.videoPlayer > .playerControls > .playerBtns{ width: 100%; height: 45px; color: white; margin-bottom: 10%;}
.videoPlayer > .playerControls > .playerBtns > .playPauseBtn{ width: 40px; height: 100%; float: left; cursor: pointer;
    position: relative; margin-right: 2%; }
.videoPlayer > .playerControls > .playerBtns > .playPauseBtn:after{position: absolute; left: 50%; top: 50%;
    transform: translate(-50%, -50%); font-size: 180%;}	
.videoPlayer > .playerControls > .playerBtns > .pause:after{ content: '\25B6'; }	
.videoPlayer > .playerControls > .playerBtns > .play:after{ content: '\268C'; transform: translate(-50%, -50%) rotate(90deg);}	
.videoPlayer > .playerControls > .playerBtns > .jumpBackwards{ width: 40px; height: 100%; float: left; position: relative; 
    cursor: pointer;
}
.videoPlayer > .playerControls > .playerBtns > .jumpBackwards:before{ content: '10'; position: absolute;
    left: 50%; top: 50%; transform: translate(-50%, -50%); font-size: 50%; color: rgba(255, 255, 255, 0.6);
}
.videoPlayer > .playerControls > .playerBtns > .jumpBackwards:after{ content: '\21BA'; position: absolute; font-weight: lighter;
    left: 50%; top: 50%; transform: translate(-50%, -50%) rotateZ(-45deg); font-size: 190%; color: rgba(255, 255, 255, 0.6);
}
.videoPlayer > .playerControls > .playerBtns > .jumpForwards{ width: 40px; height: 100%; float: left; position: relative;
    cursor: pointer;
}
.videoPlayer > .playerControls > .playerBtns > .jumpForwards:before{ content: '10'; position: absolute;
    left: 50%; top: 50%; transform: translate(-50%, -50%); font-size: 50%; color: rgba(255, 255, 255, 0.6)
}
.videoPlayer > .playerControls > .playerBtns > .jumpForwards:after{ content: '\21BB'; position: absolute; font-weight: lighter;
    left: 50%; top: 50%; transform: translate(-50%, -50%) rotateZ(45deg); font-size: 190%; color: rgba(255, 255, 255, 0.6)
}
.videoPlayer > .playerControls > .playerBtns > .playerVideoTitle{ width: 400px; height: 100%; float: left; display: flex;
    font-size: 100%; max-width: 100%;}
.videoPlayer > .playerControls > .playerBtns > .playerVideoTitle > span{ padding: 3%; display: flex; width: 70px; white-space: nowrap;
    text-transform: uppercase;}
.videoPlayer > .playerControls > .playerBtns > .playerVideoTitle > .midDot{padding: 3% 0% 3%0%;}
.videoPlayer > .playerControls > .playerBtns > .playerVideoTitle > span:first-child{ width: 100%; }
.videoPlayer > .playerControls > .playerBtns > .playerVideoTitle > span:last-child{ width: 100%; }

.videoPlayer > .playerControls > .playerBtns > .playerSubtitles{ width: 40px; height: 100%; float: right; position: relative;
    cursor: pointer; visibility: hidden;
}
.videoPlayer > .playerControls > .playerBtns > .playerSubtitles:after{ content: 'cc'; position: absolute; width: 30px; 
    border-radius: 3px; background-color: white; color: black; text-align: center; 
    left: 50%; top: 50%; transform: translate(-50%, -50%); }
.videoPlayer > .playerControls > .playerBtns > .subtitlesOn:after{background-color: var(--main-gold-color); }
.videoPlayer > .playerControls > .playerBtns > .playerSettings{ width: 40px; height: 100%; float: right; position: relative;
    cursor: pointer; visibility: hidden;
}
.videoPlayer > .playerControls > .playerBtns > .playerSettings:after{ content: '\2699'; position: absolute; font-size: 190%; 
    left: 50%; top: 50%; transform: translate(-50%, -50%); }
.videoPlayer > .playerControls > .playerBtns > .playerFullscreen{ width: 40px; height: 100%; float: right; position: relative;
    cursor: pointer;
}
.videoPlayer > .playerControls > .playerBtns > .playerFullscreen > .theIcon{width: 100%; height: 100%;}

.videoPlayer > .playerOptions {width: 100%; }
.videoPlayer > .playerOptions > button{margin: 2% 0% 2% 2%; cursor: pointer; font-weight: bolder;
    border-radius: 3px; height: 30px;  min-width: 60px; border: none; float: right;
}
.videoPlayer > .playerOptions > .saveVideo{background-color: white;}
.videoPlayer > .playerOptions > .watchLater{ background-color: white;}



.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--main-gold-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

@media only screen and (max-width: 600px) {
    .videoPlayer{width: 100%;}
    .fullscreen{transform: translate(-50%, -40%) rotateZ(90deg);}

    .videoPlayer > .playerControls > .playerProgress > .vidProgress{width: 70%;}
    .videoPlayer > .playerControls > .playerProgress > .playerTime{width: 30%; height: 100%; color: white; font-size: 80%;}
    .videoPlayer > .playerControls > .playerBtns > .playerVideoTitle{ width: 200px; padding: 2% 0% 3% 0%;}
    .videoPlayer > .playerOptions > button{margin: 3% 0% 2% 2%; height: 25px; font-size: 60%;}
}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
	/*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
	/*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	/*iPad Pad 1, 2, Mini and Air*/
	

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
	/*iPad*/
    .videoPlayer{width: 98%;}
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
	/*iPad Pro*/
}



/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-height: 600px) and (orientation: landscape) {
	.videoPlayer{width: 80%; height: 85%;}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1024px)and (device-height:1366px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad Pro*/

}

@media only screen and (device-width: 768px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad*/
	
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
	/*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
	/*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
	/*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 5 &SE*/

}