/* .createMovie{ margin: 5% 5% 5% 0%; } */

.uploadFormHolder{ border-radius: inherit; background-color: white;}
.uploadFormHolder > form{width: 95%; padding: 2.5%; }
.uploadInput{width: 47.5%; display: inline-block; margin: 1% 0% 1% 1.75%; }
.uploadInput > .materialField{width: 100%; }
.uploadInput > .materialButton{width: 100%; }
.uploadSubmit {width: 96.5%; }
.uploadDescription {width: 96.5%; }
/* #chooseSeries{display: none;} */
#isPartOf{display: none;}
#partNumber{display: none;}
#episodeNumber{display: none;}

.uploadProgressContainer {width: 100%; }
.uploadProgressContainer > .uploadProgressUpdate{width: 100%; }


@media only screen and (max-width: 600px) {
    .uploadInput:nth-child(1){width: 98%; display: inline-block; margin: 1% 0% 1% 1.75%; }
    .uploadInput:nth-child(2){width: 98%; display: inline-block; margin: 1% 0% 1% 1.75%; }
}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
    /*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
    /*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
    /*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
    /*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    /*iPad Pad 1, 2, Mini and Air*/
    

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
    /*iPad*/
    
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
    /*iPad Pro*/
    
}



/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-height: 600px) and (orientation: landscape) {
    
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1024px)and (device-height:1366px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
    /*iPad Pro*/

}

@media only screen and (device-width: 768px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
    /*iPad*/
    
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
    /*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
    /*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
    /*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
    /*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
    /*iPhone 5 &SE*/

}