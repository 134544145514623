.backdropVouchers{
    position: fixed; z-index: 10; top: 0%; left: 0%; width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
}
.popupCurrent{
    position: fixed; z-index: 11; width: 75%; top: 50%; left: 50%; transform: translate(-50%, -50%); border-radius: 15px;
    height: 90vh; background-color: white; box-shadow: 0px 0px 15px black; overflow: hidden;
	opacity: 1; animation-name: slideInBottom;
	animation-iteration-count: 1; animation-timing-function: ease-in-out; animation-duration: 0.5s;
}

.popupCurrent > h1{ text-align: center; height: 10%; margin: 0%; }
.popupCurrent > .vouchersList{ height: 90%; overflow-x: hidden; overflow-y: auto; }
.popupCurrent > .vouchersList:before{ content: ''; position: absolute; background-color: silver; width: 95%; height: 1px; left: 50%;
    transform: translateX(-50%); 
}
.popupCurrent > .vouchersList > p{ width: 100%; text-align: center; color: var(--main-dark-color);}
.popupCurrent > .vouchersList > .materialButton{width: 90%; margin: 5%}

.searchUsesInputDiv{ width: 45%; height: 50px; margin: 5%;}
.searchUsesInputDiv .searchUsesInput{ width: 100%;}

@media only screen and (max-width: 600px) {
    .popupCurrent{width: 96%;}
    .popupCurrent > h1{font-size: 130%;}

	.popupCurrent > .vouchersList > .materialButton{width: 98%; margin: 1%}
	.searchUsesInputDiv{ width: 100%; }
	.searchUsesInputDiv .searchUsesInput{ width: 100%;}



}

@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
	/*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
	/*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	/*iPad Pad 1, 2, Mini and Air*/
	

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
	/*iPad*/

}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
	/*iPad Pro*/
}

 @media only screen and (device-width: 1280px)and (device-height:800px)and (-webkit-device-pixel-ratio:1){
	/*laptop with mdpi screen*/
  }
  @media only screen and (device-width: 1280px)and (device-height:950px)and (-webkit-device-pixel-ratio:1){
	/*laptop with touch screen*/
  }
  
  @media only screen and (device-width: 1366px)and (device-height:768px){
	/*HP laptop screen*/
  }
  
  @media only screen and (device-width: 1440px)and (device-height:900px)and (-webkit-device-pixel-ratio:2){
	/*laptop with hidpi screen*/
 }


/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-height: 600px) and (orientation: landscape) {
	


}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1366px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad Pro*/

}

@media only screen and (device-width: 1024px)and (device-height:768px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad*/
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
	/*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
	/*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
	/*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 5 &SE*/

}
