.addCategoryBackdrop{
    position: fixed; z-index: 10; top: 0%; left: 0%; width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
}
.addCategoryPopup{
    position: fixed; z-index: 11; width: 45%; top: 50%; left: 50%; transform: translate(-50%, -50%); min-height: 200px; 
    border-radius: 15px; background-color: white; box-shadow: 0px 0px 15px black; max-height: 90vh;
	opacity: 1; animation-name: slideInBottom;
	animation-iteration-count: 1; animation-timing-function: ease-in-out; animation-duration: 0.5s;
}
.addCategoryPopup > h1{ text-align: center;}
.addCategoryPopup > form{width: 96%; height: 96%; margin: 2%; border-radius: inherit; }
.addCategoryPopup > form > .submitBtn{width: 96%; height: 40px; margin: 2%; ; border: none; cursor: pointer; font-size: 120%;}
.addCategoryPopup > form > div {width: 43.5%; margin: 2.5%; height: 30px; border-radius: inherit; display: inline-block; padding: 0.5%;}
.addCategoryPopup > form > .descriptionSection {width: 94%;}
.addCategoryPopup > form > div > div {display: flex;}
.addCategoryPopup > form > div > div > img{width: 5%; height: 100%; object-fit: cover;}
.addCategoryPopup > form > div > input, select{width: 100%; height: 100%; border-radius: inherit; border: none; font-size: 110%;}

.addCategoryPopup > form > div > .materialField{width: 100%;}


@media only screen and (max-width: 600px) {
    .addCategoryPopup{ width: 98%; }

}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
	/*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
	/*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	/*iPad Pad 1, 2, Mini and Air*/
	

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
	/*iPad*/
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
	/*iPad Pro*/
}



/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (orientation: landscape) {
	
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1024px)and (device-height:1366px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad Pro*/

}

@media only screen and (device-width: 768px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad*/
	
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
	/*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
	/*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
	/*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 5 &SE*/

}