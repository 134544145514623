.contentManager{width: 100%; height: 100%; border-radius: inherit; }

.contentManager > .lowerCard{ margin: 0%; height: 100%; overflow: auto;}
.contentManager > .lowerCard > .contentActions{margin: 4% 0% 0% 0%; }
.contentManager > .lowerCard > .contentActions > h1{margin: 0%; padding-left: 2%;}
.contentManager > .lowerCard > .contentActions > .actionContainer{width: 96%; min-height: 30vh; margin: 2%; 
    border-radius: 15px; 
}

.contentManager > .lowerCard > .contentActions > .actionContainer > a{ height: 50px; width: 200px; margin-top: 2%; 
    border-radius: inherit; display: inline-block; border: 1px solid silver; cursor: pointer; position: relative;
    text-decoration: none; margin: 2%;}
.contentManager > .lowerCard > .contentActions > .actionContainer > a.organiser{ border: 2px dashed silver; height: 25px;
    margin-top: -10%; }
.contentManager > .lowerCard > .contentActions > .actionContainer > a.uploader{ font-size: 200%; width: 50px; height: 50px;
    border-radius: 50%;}
.contentManager > .lowerCard > .contentActions > .actionContainer > a.active{background-color: var(--main-gold-color);}
.contentManager > .lowerCard > .contentActions > .actionContainer > a.active > p{color: white;}
.contentManager > .lowerCard > .contentActions > .actionContainer > a > p{position: absolute; margin: 0%; left: 50%; top: 50%;
    transform: translate(-50%, -50%); font-size: 120%;
}
.contentManager > .lowerCard > .contentActions > .actionContainer > .tabsContainer{border-radius: inherit; min-height: 100px; }
.dashMoviesContainer > .dashContentCover{ width: 100%; height: 170px; position: relative; border-radius: 25px 25px 3px 3px;}
.dashMoviesContainer > .dashContentCover > h3{  position: absolute; z-index: 2; }
.dashMoviesContainer > .dashContentCover > p{  position: absolute; margin-top: 10%;}
.dashMoviesContainer > .dashContentCover > div{ width: 100%; height: 100%; position: absolute; border-radius: inherit;}
.dashMoviesContainer > .dashContentCover > div > img{  width: 100%; height: 100%; object-fit: cover; border-radius: inherit;}
.dashMoviesContainer > .moreSeries{
    white-space: nowrap; overflow-x: auto; margin-top: 1%; scroll-behavior: smooth;
	padding-top: 10px; padding-bottom: 10px; 
}
.dashContentCover > .icon-button-file{ position: absolute; right: 2%; top: 1%; z-index: 2; color: var(--main-gold-color);
    border: 1px solid var(--main-gold-color); border-radius: 50%; text-shadow: var(--main-gold-color);}
.dashContentCover > .icon-button-file > .myIconBtn{color: var(--main-gold-color);}
.dashContentCover > #icon-button-file{visibility: hidden;}
.homeCover { position: relative; width: 100%; height: 200px; border-radius: 15px; background-color: rgba(250, 250, 250, 0.7);}
.homeCover > .icon-button-file{ position: absolute; color: var(--main-gold-color);}
.homeCover > .icon-button-file > .myIconBtn{color: var(--main-gold-color);}
.homeCover > .theHomeCover{width: 100%; height: 100%; position: absolute; border-radius: inherit; overflow: hidden;}
.homeCover > .theHomeCover > img{width: 100%; height: 100%; object-fit: cover; border-radius: inherit;}


@media only screen and (max-width: 600px) {
    .contentManager > .lowerCard > .contentActions > h1{font-size: 22px;}
    .contentManager > .lowerCard > .contentActions > .actionContainer > a{ height: 50px; width: 43%;}

}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
    /*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
    /*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
    /*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
    /*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    /*iPad Pad 1, 2, Mini and Air*/
    

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
    /*iPad*/
    
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
    /*iPad Pro*/
    
}



/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-height: 600px) and (orientation: landscape) {
    .contentManager > .lowerCard > .contentActions > h1{font-size: 20px;}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
    
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1024px)and (device-height:1366px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
    /*iPad Pro*/

}

@media only screen and (device-width: 768px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
    /*iPad*/
    
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
    /*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
    /*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
    /*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
    /*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
    /*iPhone 5 &SE*/

}