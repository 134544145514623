.subscriptionBackground{position: fixed; z-index: 3; left: 0%; top: 0%; width: 100%; height: 100%;
	background-color: rgba(0, 0, 0, 0.7);}
.subscriptionContatiner{position: absolute; z-index: 3; width: 38%; border-radius: 25px; margin-left: 30%; margin-top: 10%; 
	background-color: white; box-shadow: 0px 5px 15px black;
	padding: 2%;
}
.subscriptionContatiner > .closeSubscription{width: 34px; height: 34px; border-radius: 50%; font-size: 140%; margin: -3%; border: none; cursor: pointer;
	position: absolute; z-index: 3; transform: rotateY(180deg); background-color: rgba(255, 255, 255, 0.9); color: black;
	backdrop-filter: blur(15px); -webkit-backdrop-filter: blur(15px); -moz-backdrop-filter: blur(15px)}
.subscriptionContatiner > h3{width: 98%; text-align: center; margin: -2%; }
.subscriptionContatiner > .methodsChoice{width: 100%; display: flex; border-radius: 25px; border-top: 1px solid silver;}
.subscriptionContatiner > .methodsChoice > a{text-decoration: none; color: black; margin-left: 2%;}
.subscriptionContatiner > .methodsChoice > a.active{border-bottom: 2px solid var(--main-dark-color);}
.subscriptionContatiner > .methodsChoice > a > .methods{width: 100%;}
.subscriptionContatiner > .membershipOptions {width: 96%; min-height: 200px; border-radius: inherit;}
.subscriptionContatiner > .membershipOptions  > h4{margin-top: 5%; text-align: center; display: none; }
.subscriptionContatiner > .paymentMethods {width: 96%; min-height: 200px; border-radius: inherit; display: none;}
.subscriptionContatiner > .paymentMethods  > h4{margin-top: 5%; text-align: center; display: none; }

#payfast_div_container{z-index: 100;}


@media only screen and (max-width: 600px) {
    .subscriptionContatiner{width: 94%; margin-left: 1%; margin-top: 15%; }
	

}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
	/*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
	/*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	/*iPad Pad 1, 2, Mini and Air*/
	

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
	/*iPad*/
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
	/*iPad Pro*/
}



/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (orientation: landscape) {
	
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1024px)and (device-height:1366px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad Pro*/

}

@media only screen and (device-width: 768px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad*/
	
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
	/*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
	/*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
	/*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 5 &SE*/

}