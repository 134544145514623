

.homeBackground{position: relative; width: 100%; height: 90%; background-repeat: no-repeat, no-repeat; -webkit-background-size: cover; 
    -moz-background-size: cover; -o-background-size: cover; background-size: cover; border: none;}
.homeBackground:before{content: ''; position: absolute; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.3);}

.watchLaterBackground{height: 50%;}
.homeBackground > .categories{position: absolute; transform: translate(-50%, -50%); top: 20%; left: 50%; border-radius: 5px;
	width: 60%; height: 50px; }
.homeBackground > .categories > .category{width: 150px; height: 40px; margin: 0.5%; display: inline-block; cursor: pointer;
	border-radius: inherit; }
.homeBackground > .categories > .active{transform: scale(1.1); box-shadow: 0px 5px 15px silver;}
.homeBackground > .backgroundImage{object-fit: cover; width: 100%; height: 100%; position: absolute; z-index: 0; border: none;
	/* display: none; */
}


.homeBackground > a.nudgeLogin, button.nudgeLogin{position: absolute; float: right; width: 150px; top: 50%; right: 2%;
	transform: translate(-50%, -50%); background-color: var(--main-gold-color); border-radius: 50px; text-transform: uppercase;
	border: none; box-shadow: 5px 5px 10px rgba(255, 255, 0, 0.6);
	text-decoration: none; text-align: center; color: black;
}
.homeBackground > a.nudgeLogin > p { color: black;}

.homeBackground> .recentMovie{position: absolute; width: 55%; height: 55%; margin-top: 14%; display: flex;}
.homeBackground> .recentMovie>.movieThumb{ margin-left: 3%; width: 240px; height: 326px; float: left; overflow: hidden; 
	border-radius: 8px 8px 10px 10px; background: white; cursor: pointer; box-shadow: 0px 0px 15px rgba(30, 20, 20, 0.5);
}
.homeBackground> .recentMovie>.movieThumb> .thumbnail{width: 100%; height: 88%; overflow: hidden;}
.homeBackground> .recentMovie>.movieThumb> .thumbnail>img{width: 100%; height: 100%; object-fit: cover;}
.homeBackground> .recentMovie>.movieThumb> h3{margin: 0; color: black; text-transform: uppercase; font-weight: bolder;
	text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.7);}
.homeBackground> .recentMovie>.movieThumb> p{margin: 0; color: var(--main-gold-color);}
.homeBackground> .recentMovie>.movieInfo{width: 70%; height: 85%; padding-left: 1%; float: right}
.homeBackground> .recentMovie>.movieInfo>h3{color: white; text-transform: uppercase; letter-spacing: 0px;}
.homeBackground> .recentMovie>.movieInfo > .recentMovieViews{color: white; }
.homeBackground> .recentMovie>.movieInfo > .recentMovieViews > p{margin: 0%;}
.homeBackground> .recentMovie>.movieInfo > .recentMovieViews > p > span{color: lime; visibility: hidden;}
.homeBackground> .recentMovie>.movieInfo>p{color: white;}
.homeBackground> .recentMovie>.movieInfo> button{width: 100px; height: 35px; border: 2px solid var(--main-gold-color); 
	background-color: var(--main-dark-color); color: var(--main-gold-color); border-radius: 25px; position: relative; cursor: pointer;}
.homeBackground> .recentMovie>.movieInfo> button:after{content: '\21BB'; position: absolute; border-radius: 50%; width: 25px; height: 25px;
	background-color: var(--main-gold-color); color: black; right: -40%; top: 15%; font-size: 160%; cursor: pointer;
}
.watchLaterBackground > .watchLaterHead {margin-top: 12%;}
.watchLaterBackground > .watchLaterHead > h1{margin-left: 4%; color: white; }


.noContentP{width: 100%; text-align: center;}
.moviesContainer{position: relative; margin-top: -8%; z-index: 1;width: 100%; min-height: 20vh; /*border: 1px solid red;*/
	background: linear-gradient(to bottom, transparent 0%, var(--main-dark-color) 7%, var(--main-dark-color) 100%);
	color: white;
}
.watchLater{margin-top: -5%;}
.moviesContainer> h3{color: white; margin: 3% 0% 0% 2%; }
.moviesContainer> .newReleases{border: none; color: white;}
.moviesContainer> .popularMovies{color: white;}

.moviesContainer> .centerNewMovies {white-space: nowrap; overflow-x: auto; margin-top: 1%; scroll-behavior: smooth;
	padding-top: 10px; padding-bottom: 10px;
}
.moviesContainer> .centerNewMovies::-webkit-scrollbar {width: 2px; height: 2px;
	/*background: var(--main-gold-color);*/
}


.movie{ width: 18.5%; height: 350px; margin:  0.5% 1.5% 0% 1.5%; display: inline-block;
	background: white; border-radius: 10px 10px 10px 10px; overflow: hidden; transition: 0.2s ease-in-out; cursor: pointer;
	position: relative;
}
.loaderMovie{ position: relative; transition-delay: 1s ease-in-out;
	width: 18.5%; height: 350px; margin:  0.5% 1.5% 0% 1.5%; display: inline-block;
	background: silver; border-radius: 10px 10px 10px 10px; overflow: hidden; transition: 0.2s ease-in-out; cursor: pointer;
}
.loaderSeries{ width: 200px/*180px*/; height: 200px/*180px*/; }
.loaderTvShow{ width: 350px/*320px*/; height: 200px/*180px*/; }
.loaderMovie:nth-child(2){opacity: 0.9;}
.loaderMovie:nth-child(3){opacity: 0.7;}
.loaderMovie:nth-child(4){opacity: 0.5;}
.loaderMovie:nth-child(5){opacity: 0.3;}
.loaderMovie:nth-child(6){opacity: 0.1;}
.loaderMovie:nth-child(7){opacity: 0.05;}
.loaderMovie > p {width: 70%; background-color: rgba(230, 230, 230, 1); border-radius: inherit; margin-left: 5%;
	color: transparent;}
.loaderMovie > p:nth-child(2) {width: 90%; height: 50px; background-color: rgba(240, 240, 240, 1); }

.movie > .movieThumbnal{width: 100%; height: 87%; background: var(--main-gold-color); }
.movie > .movieThumbnal > img{width: 100%; height: 100%; object-fit: cover;}
.movie> h4{margin: 1%; height: 5%; color: black; text-transform: uppercase;}
.movie> p{margin: 1% 1% 2% 1%; height: 5%; color: var(--main-gold-color);}
.movie > .infoDiv{position: absolute; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.8); display: none; 
	transition: 0.5s ease-in-out; }
.movie > .infoDiv>h1{margin: 2%; font-size: 100%;}
.movie > .infoDiv > p{color: white; width: 96%; padding: 2%; font-size: 75%; padding: 2%; word-break: break-all; white-space: normal;}
.movie:hover{transform: scale(1.05); transition: 0.5s ease-in-out; box-shadow: 0px 0px 15px rgba(255, 255, 0, 0.3);}
.movie:hover > .infoDiv{display: block; transition: 0.5s ease-in-out;}


.series{ width: 200px; height: 200px; margin:  0.5% 1.5% 0% 1.5%; display: inline-block;
	background: white; border-radius: 10px 10px 10px 10px; overflow: hidden; transition: 0.2s ease-in-out; cursor: pointer;
	position: relative;
}
.series> .infoDiv{position: absolute; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.8); display: none; 
	transition: 0.5s ease-in-out; text-overflow: break-word; overflow: hidden;}
.series > .infoDiv>h1{margin: 2%; font-size: 100%;}
.series > .movieThumbnal{width: 100%; height: 85%; background: var(--main-gold-color); }
.series > .movieThumbnal > img{width: 100%; height: 100%; object-fit: cover;}
.series> h4{margin: 1%; height: 5%; color: black; text-transform: uppercase;}
.series> p{margin: 1% 1% 2% 1%; height: 5%; color: var(--main-gold-color);}
.series > .infoDiv > p{color: white; width: 96%; padding: 2%; font-size: 75%; padding: 2%;  word-break: break-all;  white-space: normal;}
.series:hover{transform: scale(1.05); transition: 0.5s ease-in-out; box-shadow: 0px 0px 15px rgba(255, 255, 0, 0.3);}
.series:hover > .infoDiv{display: block; transition: 0.5s ease-in-out;}

.tv-show{ width: 350px; height: 200px; margin:  0.5% 1.5% 0% 1.5%; display: inline-block;
	background: white; border-radius: 10px 10px 10px 10px; overflow: hidden; transition: 0.2s ease-in-out; cursor: pointer;
	position: relative;
}


.tv-show> .infoDiv{position: absolute; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.8); display: none; 
	transition: 0.5s ease-in-out; text-overflow: break-word; overflow: hidden;}
.tv-show>.infoDiv> h1{margin: 2%; font-size: 100%;}
.tv-show>.infoDiv> p{font-size: 75%;}
.tv-show > .movieThumbnal{width: 100%; height: 83%; background: var(--main-gold-color); }
.tv-show > .movieThumbnal > img{width: 100%; height: 100%; object-fit: cover;}
.tv-show> h4{margin: 1%; height: 5%; color: black; text-transform: uppercase;}
.tv-show> p{margin: 1% 1% 2% 1%; height: 5%; color: var(--main-gold-color);}
.tv-show > .infoDiv > p{color: white; width: 96%; padding: 2%; padding: 2%;  word-break: break-all;  white-space: normal;}
.tv-show:hover{transform: scale(1.05); transition: 0.5s ease-in-out; box-shadow: 0px 0px 15px rgba(255, 255, 0, 0.3);}
.tv-show:hover > .infoDiv{display: block; transition: 0.5s ease-in-out;}



@media only screen and (max-width: 600px) {
	.homeBackground{ height: 85%; }
		
	.homeBackground > .categories{top: 20%; left: 50%; border-radius: 5px; width: 60%; height: 50px; }
	.homeBackground > .categories > .category{width: 150px; height: 40px; margin: 0.5%; }
	.homeBackground > .categories > .active{transform: scale(1.1); box-shadow: 0px 5px 15px silver; }
	
	.homeBackground > a.nudgeLogin, button.nudgeLogin{width: 150px; top: 50%; left: 50%; right: unset; float: unset;
		transform: translate(-50%, -50%) scale(0.7);
	}
	
	.homeBackground> .recentMovie{position: absolute; width: 100%; height: 45%; margin-top: 65%; display: unset;
	}
	.homeBackground> .recentMovie>.movieThumb{ width: 100%; height: 65%; float: left; visibility: hidden; }
	.homeBackground> .recentMovie>.movieInfo{ padding-left: 3%; float: unset; }
	.homeBackground> .recentMovie>.movieInfo>h3{color: white; text-transform: uppercase; letter-spacing: 0px;}
	.homeBackground> .recentMovie>.movieInfo > .recentMovieViews{color: white; display: none;}
	.homeBackground> .recentMovie>.movieInfo>p{display: none;}
	.homeBackground> .recentMovie>.movieInfo> button{width: 100px; height: 35px; border: 2px solid var(--main-gold-color); 
	}
	/* .watchLaterBackground > .watchLaterHead {margin-top: 12%;} */
	/* .watchLaterBackground > .watchLaterHead > h1{margin-left: 4%; color: white; } */
	


	.movie{ width: 115px; height: 175px; border-radius: 7px 7px 7px 7px; }
	.loaderMovie{width: 115px; height: 175px; border-radius: 7px 7px 7px 7px; }
	.loaderSeries{ width: 100px/*180px*/; height: 100px/*180px*/; border-radius: 7px 7px 7px 7px; }
	.loaderTvShow{ width: 160px/*320px*/; height: 100px/*180px*/; border-radius: 7px 7px 7px 7px; }
	.loaderMovie > p { height: 25px;  }
	.loaderMovie > p:nth-child(2) { height: 15px; }
	.movie > .infoDiv > {font-size: 5px;}
	.movie > .infoDiv > h1{font-size: 10px;}
	.movie > .infoDiv > p{font-size: 7px;}

	.movie > .movieThumbnal{width: 100%; height: 85%;}
	.movie> h4{margin: 1%; height: 5%; font-size: 15px;}
	.movie> p{margin: 2% 2% 2% 1%; height: 5%; font-size: 12px;}


	.series{ width: 100px; height: 100px; border-radius: 7px 7px 7px 7px; }
	.series > .movieThumbnal{width: 100%; height: 76%;}
	.series> h4{margin: 1%; height: 8%; font-size: 13px;}
	.series> p{margin: 2% 2% 2% 1%; height: 5%; font-size: 11px;}
	.series > .infoDiv > h1{font-size: 10px;}
	.series > .infoDiv > p{font-size: 7px;}
	.tv-show{ width: 160px; height: 100px; border-radius: 7px 7px 7px 7px; }
	.tv-show > .movieThumbnal{width: 100%; height: 76%;}
	.tv-show> h4{margin: 1%; height: 8%; font-size: 13px;}
	.tv-show> p{margin: 2% 2% 2% 1%; height: 5%; font-size: 11px;}
	.tv-show > .infoDiv > h1{font-size: 10px;}
	.tv-show > .infoDiv > p{font-size: 7px;}
}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
	/*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
	/*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	/*iPad Pad 1, 2, Mini and Air*/
	

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
	/*iPad*/
	.homeBackground> .recentMovie{width: 75%; height: 65%; margin-top: 20%}
	/* .homeBackground> .recentMovie>.movieThumb{width: 60%; height: 70%;} */

	.movie{ width: 200px; height: 320px;}
	.loaderMovie{width: 100px; height: 160px; }
	.loaderSeries{ width: 100px/*180px*/; height: 100px/*180px*/; }
	.loaderTvShow{ width: 160px/*320px*/; height: 100px/*180px*/; }
	.loaderMovie > p { height: 25px;  }
	.loaderMovie > p:nth-child(2) { height: 15px; }
	.movie > .infoDiv > {font-size: 5px;}

	.movie > .movieThumbnal{width: 100%; height: 87%;}
	.movie> h4{margin: 1%; height: 5%; font-size: 17px;}
	.movie> p{margin: 2% 2% 2% 1%; height: 5%; font-size: 12px;}


	.series{ width: 160px; height: 160px;  }
	.series > .movieThumbnal{height: 82%;}
	.series> h4{margin: 1%; height: 7%; font-size: 15px;}
	.series> p{margin: 2% 2% 2% 1%; height: 5%; font-size: 10px;}
	.tv-show{ width: 180px; height: 140px;  }
	.tv-show > .movieThumbnal{height: 80%;}
	.tv-show> h4{margin: 1%; height: 7%; font-size: 15px;}
	.tv-show> p{margin: 2% 2% 2% 1%; height: 5%; font-size: 10px;}
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
	/*iPad Pro*/
	.homeBackground> .recentMovie{width: 75%; height: 65%; margin-top: 15%}
	/* .homeBackground> .recentMovie>.movieThumb{width: 60%; height: 70%;} */
	.movie{ width: 250px; height: 375px;}
	.series{ width: 280px; height: 260px;  }
	.tv-show{ width: 350px; height: 280px;  }
}

 @media only screen and (device-width: 1280px)and (device-height:800px)and (-webkit-device-pixel-ratio:1){
	/*laptop with mdpi screen*/
	
  }
  @media only screen and (device-width: 1280px)and (device-height:950px)and (-webkit-device-pixel-ratio:1){
	/*laptop with touch screen*/
  }
  
  @media only screen and (device-width: 1366px)and (device-height:768px){
	/*HP laptop screen*/
  }
  
  @media only screen and (device-width: 1440px)and (device-height:900px)and (-webkit-device-pixel-ratio:2){
	/*laptop with hidpi screen*/
 }



/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-height: 600px) and (orientation: landscape) {
	.homeBackground> .recentMovie>.movieInfo> button{width: 85px; height: 27px; border: 2px solid var(--main-gold-color); }
    .homeBackground > a.nudgeLogin, button.nudgeLogin{width: 120px;  font-size: 80%;}

	.homeBackground> .recentMovie{width: 75%; height: 65%; margin-top: 12%;}
	.homeBackground> .recentMovie>.movieThumb{width: 23%; height: 80%;}

	.movie{ width: 170px; height: 290px;}
	.movie > .movieThumbnal{width: 100%; height: 89%;}
	.movie> h4{height: 5%; font-size: 13px;}
	.movie> p{height: 5%; font-size: 11px;}
	.series{ width: 200px; height: 210px;}
	.series > .movieThumbnal{width: 100%; height: 85%;}
	.series> h4{height: 5%; font-size: 13px;}
	.series> p{height: 5%; font-size: 11px;}
	.tv-show{height: 210px; width: 240;}
	.tv-show > .movieThumbnal{width: 100%; height: 85%;}
	.tv-show> h4{height: 5%; font-size: 13px;}
	.tv-show> p{height: 5%; font-size: 11px;}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1366px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad Pro*/
	.homeBackground> .recentMovie{width: 60%; height: 65%; margin-top: 13%}
	.homeBackground> .recentMovie>.movieThumb{width: 40%; height: 70%;}
	.movie{ width: 250px; height: 375px;}
	.tv-show{height: 260px;  }
	.series{ width: 280px; height: 260px;}

}

@media only screen and (device-width: 1024px)and (device-height:768px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad*/
	.homeBackground> .recentMovie{width: 60%; height: 65%; margin-top: 13%}
	.homeBackground> .recentMovie>.movieThumb{width: 35%; height: 70%;}
	.movie{ width: 245px; height: 370px;}
	.series{ width: 280px; height: 260px;}
	.tv-show{height: 250px;}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
	/*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
	/*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
	/*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 568px)and (device-height:320px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 5 &SE*/
	.homeBackground> .recentMovie{position: absolute; width: 100%; height: 45%; margin-top: 65%; display: unset;
	}
}


@keyframes beforePreview {
	0% {opacity: 1; transform: scale(1);}
	100% {opacity: 0; transform: scale(1.05);}
}
@keyframes afterPreview {
	0% {opacity: 0; transform: scale(1.001);}
	50% {opacity: 0; }
	100% {opacity: 1; transform: scale(1);}
}

@keyframes fadeInOpacity {
	0% {opacity: 0; transform: translateY(0%);}
	100% {opacity: 1; transform: translate(50%);}
}

@keyframes slideInTop {
	0% { opacity:0; -webkit-transform: translateY(-50px); }
	80% { opacity:0.8; -webkit-transform: translateY(2px);}
	100% { opacity:1; -webkit-transform: translateY(0px); }
} 
@keyframes slideOutTop {
	0% { opacity:1; -webkit-transform: translateY(0px); }
	100% { opacity:0; -webkit-transform: translateY(-25px); }
}