.landing{width: 100%; height: 100%; border-radius: inherit; }

.landing > .upperCard{border: 1px solid red; margin: 0%;}
.landing > .lowerCard{ margin: 0%;}
.landing > .lowerCard > .userActivity{margin: 4% 0% 0% 0%; }
.landing > .lowerCard > .userActivity > h1{margin: 0%; padding-left: 2%;}
.landing > .lowerCard > .userActivity > .vocuherActions{width: 96%; height: 40vh; margin: 2%; border-radius: 15px; /*background-color: white*/
    }
.landing > .lowerCard > .userActivity > .vocuherActions > div{ height: 40%; margin-top: 2%; border-radius: inherit; display: inline-block;
    /*background-color: white;*/ border: 1px solid silver; cursor: pointer;}
.landing > .lowerCard > .userActivity > .vocuherActions > .generate{width: 30%; background-color: white; position: relative;}
.landing > .lowerCard > .userActivity > .vocuherActions > .generate > p{position: absolute; left: 50%; top: 50%; color: silver;
    transform: translate(-50%, -50%); font: 300%;
}
.landing > .lowerCard > .userActivity > .vocuherActions > .currentVouchers{width: 30%; margin-left: 4.5%;}
.landing > .lowerCard > .userActivity > .vocuherActions > .verify{width: 30%; margin-left: 4.5%;}
.landing > .lowerCard > .performanceMetrics{margin: 0%; }
.landing > .lowerCard > .performanceMetrics > h1{margin: 0%; padding-left: 2%;}
.landing > .lowerCard > .performanceMetrics > .kpis{width: 96%; height: 60px; display: flex; justify-content: center; 
    margin: 0% 2% 0% 2%; border-radius: 15px; border: 1px solid silver; /*background-color: white;*/}
.landing > .lowerCard > .performanceMetrics > .kpis > div{width: 100%; position: relative;}
.landing > .lowerCard > .performanceMetrics > .kpis > div:after{position: absolute; content: ''; height: 60%; width: 1px; 
    background-color: silver; right: 0%; top: 15%;}
.landing > .lowerCard > .performanceMetrics > .kpis > div:last-child:after{display: none;}
.landing > .lowerCard > .performanceMetrics > .kpis > div > p{width: 100%; margin: 0%; font-size: 80%; text-align: center;}
.landing > .lowerCard > .performanceMetrics > .kpis > div > p:nth-child(1){margin-top: 2%;}
.landing > .lowerCard > .performanceMetrics > .kpis > div > p:nth-child(2){text-align: center; font-size: 200%; font-weight: bold;}