.userProfile{padding-top: 10%; background-color: var(--main-dark-color); }
.userProfile > .upperCard{width: 90%; min-height: 100px; margin: 5%; border-radius: 20px; box-shadow: 0px 0px 15px black;
    position: relative;
}
.userProfile > .lowerCard{width: 90%; min-height: 200px; margin: 5%; border-radius: 20px; box-shadow: 0px 0px 15px black; color: white;}

.userProfile > .upperCard > .updateProfile{position: absolute; right: 0%; top: 50%; transform: translate(-150%, -50%);
	color: var(--main-gold-color);}
.userProfile > .upperCard > .updateProfile > .updateProfileBtn{color: var(--main-gold-color);}
.userProfile > .upperCard > .handleLogOut{width: 75px; height: 35px; border-radius: 25px; background-color: red; color: white;
    position: absolute; right: 0%; top: 50%; transform: translate(-20%, -50%); 
	border: none; box-shadow: 0px 5px 25px red; text-transform: uppercase; font-weight: bold; cursor: pointer;
}

.userProfile > .upperCard > .profile-image{position: relative; width: 100px; height: 100px; border-radius: 50%; 
	overflow: hidden; border: 4px solid white; display: inline-block; margin-top: 10px; margin-left: 10px; }
.userProfile > .upperCard > .profile-image > img{width: 100%; height: 100%; border-radius: inherit;  object-fit: cover;}
.userProfile > .upperCard > .profile-image > .profilePicIcon{width: 100%; height: 100%; color: var(--main-gold-color);}

.userProfile > .upperCard > .namesPart {width: 70%; display: inline-block; margin-bottom: 10px; }
.userProfile > .upperCard > .namesPart > h1 {color: white; }
.userProfile > .upperCard > .namesPart > .rolePart {width: 100%; height: 100%; }
.userProfile > .upperCard > .namesPart > .rolePart > label {color: var(--main-gold-color);}
.userProfile > .upperCard > .namesPart > .rolePart > span {color: var(--main-dark-color); background-color: var(--main-gold-color); 
	padding: 5px 10px 5px 10px; border-radius: 25px; min-width: 100px; height: 40px;}
.userProfile > .upperCard > .namesPart > .rolePart > a {color: white; text-decoration: none; margin-left: 5%; 
	min-width: 100px; height: 40px;}
	
.userProfile > .lowerCard > div > h1 {margin: 2% 0% 2% 1%; width: 90%; color: yellow;}
.userProfile > .lowerCard > .subscriptions {width: 100%; border-radius: inherit}
.userProfile > .lowerCard > .subscriptions > div {width: 100%; border-radius: inherit;}
.userProfile > .lowerCard > .subscriptions > div > table {width: 98%; margin: 1%; border-radius: inherit;}
.userProfile > .lowerCard > .subscriptions > div > table > thead > tr > td{font-weight: bolder;}
.userProfile > .lowerCard > .subscriptions > div > table > tbody > tr > td{color: rgb(230, 230, 230);}
/* .userProfile > .lowerCard > .paymentMethods {} */



@media only screen and (max-width: 600px) {
	.userProfile{padding-top: 35%; }

	
}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
	/*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
	/*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	/*iPad Pad 1, 2, Mini and Air*/
	

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
	/*iPad*/
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
	/*iPad Pro*/
}



/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (orientation: landscape) {
	
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1024px)and (device-height:1366px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad Pro*/

}

@media only screen and (device-width: 768px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad*/
	
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
	/*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
	/*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
	/*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 5 &SE*/

}