:root{
	--main-black-color: #1a1516;
	--main-dark-color: rgb(30, 20, 20);
	--main-gold-color: #cfaa1d;
	--main-red-color: red;
	--main-lime-color: lime;
	--main-gray-color: #4b4647;
	--main-grey-color: #4b4647;
	--sub-gold-color: #d1b54d;
	--main-gold-color: #d1b54d;
}

html, body{
	margin: 0%; padding: 0%;
	font-family: 'Montserrat', sans-serif;
	width: 100%; height: 100%;
	overflow-x: hidden;overflow-y: auto; scroll-behavior: smooth;
	background: var(--main-dark-color); 
}
#root{
	margin: 0%; padding: 0%;
	font-family: 'Montserrat', sans-serif;
	width: 100%; height: 100%;
	overflow-x: hidden;overflow-y: auto; scroll-behavior: smooth; 
}

body::-webkit-scrollbar {width: 6px; height: 2px;border-radius: 5px;}
body::-webkit-scrollbar-track { background: #f1f1f1; border-radius: 5px;} 
body::-webkit-scrollbar-thumb { background: silver; border-radius: 5px;  }
body::-webkit-scrollbar-thumb:hover { background: var(--main-dark-color); border-radius: 5px;}

#root::-webkit-scrollbar {width: 6px; height: 2px;border-radius: 5px;}
#root::-webkit-scrollbar-track { background: var(--main-dark-color); border-radius: 5px;} 
#root::-webkit-scrollbar-thumb { background: silver; border-radius: 5px;  }
#root::-webkit-scrollbar-thumb:hover { background: var(--main-dark-color); border-radius: 5px;}


footer{ width: 100%; min-height: 150px; padding-top: 100px; background: var(--main-dark-color); color: white; }
footer > div.first {display: flex;}
footer > div.second {font-size: 90%; text-align: center; color: rgba(255, 255, 255, 0.7); margin-top: 50px; padding-bottom: 25px;}
footer > div > div{display: inline-block; width: 100%; min-height: 100px;}
footer > div > div > a{display: inline-block; margin: 2% 2% 2% 2%;}
footer > div > div > a:nth-child(1){margin-left: 5%; }
footer > div > div > a:nth-child(3) > div > img{transform: scale(0.9); }
footer > div > div > a > div{width: 40px; height: 40px; border-radius: 50%; background: white; overflow: hidden;}
footer > div > div > a > div > img{width: 90%; margin: 5%; }


.shineElemLoader{
	/*   positioning */
	position: absolute; opacity: 0; top: 0; left: 0;

	/*   gradient   */
	background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.03) 1%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0.85) 50%, rgba(255,255,255,0.85) 70%, rgba(255,255,255,0.85) 71%, rgba(255,255,255,0) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(1%,rgba(255,255,255,0.03)), color-stop(30%,rgba(255,255,255,0.85)), color-stop(50%,rgba(255,255,255,0.85)), color-stop(70%,rgba(255,255,255,0.85)), color-stop(71%,rgba(255,255,255,0.85)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* IE10+ */
	background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

	/*  forming the shine element
	play around with the width, skew and gradient to get different effects
	*/
	width: 15%;height: 100%;
	transform: skew(-10deg, 0deg);
	-webkit-transform: skew(-10deg,0deg);-moz-transform: skew(-10deg,0deg);-ms-transform: skew(-10deg,0deg);-o-transform: skew(-10deg,0deg);


	/*  animating it to move fast*/
	animation: move 1s;animation-iteration-count: infinite;animation-delay: 0.3s;
	-webkit-animation: move 1s;-webkit-animation-iteration-count: infinite;-webkit-animation-delay: 0.3s;
	-moz-transform: skew(-10deg,0deg);-moz-animation: move 1s;-moz-animation-iteration-count: infinite;-moz-animation-delay: 0.3s;
	-ms-transform: skew(-10deg,0deg);-ms-animation: move 1s;-ms-animation-iteration-count: infinite;-ms-animation-delay: 0.3s;
	-o-transform: skew(-10deg,0deg);-o-animation: move 1s;-o-animation-iteration-count: infinite;-o-animation-delay: 0.3s;
}
.shineElem {
	/*   positioning */
	position: absolute; opacity: 0; top: 0; left: 0;

	/*   gradient   */
	background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.03) 1%, rgba(255,255,255,0.6) 30%, rgba(255,255,255,0.85) 50%, rgba(255,255,255,0.85) 70%, rgba(255,255,255,0.85) 71%, rgba(255,255,255,0) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(1%,rgba(255,255,255,0.03)), color-stop(30%,rgba(255,255,255,0.85)), color-stop(50%,rgba(255,255,255,0.85)), color-stop(70%,rgba(255,255,255,0.85)), color-stop(71%,rgba(255,255,255,0.85)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* IE10+ */
	background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.03) 1%,rgba(255,255,255,0.6) 30%,rgba(255,255,255,0.85) 50%,rgba(255,255,255,0.85) 70%,rgba(255,255,255,0.85) 71%,rgba(255,255,255,0) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

	/*  forming the shine element
	play around with the width, skew and gradient to get different effects
	*/
	width: 15%;height: 100%;
	transform: skew(-10deg, 0deg);
	-webkit-transform: skew(-10deg,0deg);-moz-transform: skew(-10deg,0deg);-ms-transform: skew(-10deg,0deg);-o-transform: skew(-10deg,0deg);


	/*  animating it to move*/
	animation: move 2s;animation-iteration-count: infinite;animation-delay: 1s;
	-webkit-animation: move 2s;-webkit-animation-iteration-count: infinite;-webkit-animation-delay: 1s;
	-moz-transform: skew(-10deg,0deg);-moz-animation: move 2s;-moz-animation-iteration-count: infinite;-moz-animation-delay: 1s;
	-ms-transform: skew(-10deg,0deg);-ms-animation: move 2s;-ms-animation-iteration-count: infinite;-ms-animation-delay: 1s;
	-o-transform: skew(-10deg,0deg);-o-animation: move 2s;-o-animation-iteration-count: infinite;-o-animation-delay: 1s;
}




/* keyframes for move animation */
@keyframes move { 0%  { left: 0; opacity: 0; } 5% {opacity: 0.0} 48% {opacity: 0.2} 80% {opacity: 0.0} 100% { left: 82%}}
@-webkit-keyframes move { 0%  { left: 0; opacity: 0; } 5% {opacity: 0.0} 48% {opacity: 0.2} 80% {opacity: 0.0} 100% { left: 82%}}
@-moz-keyframes move { 0%  { left: 0; opacity: 0; } 5% {opacity: 0.0} 48% {opacity: 0.2} 80% {opacity: 0.0} 100% { left: 88%}}
@-ms-keyframes move { 0%  { left: 0; opacity: 0; } 5% {opacity: 0.0} 48% {opacity: 0.2} 80% {opacity: 0.0} 100% { left: 82%}}
@-o-keyframes move {0%  { left: 0; opacity: 0; } 5% {opacity: 0.0} 48% {opacity: 0.2} 80% {opacity: 0.0} 100% { left: 82%}}



@media only screen and (max-width: 600px) {
	footer{ min-height: 150px; padding-top: 100px; }
	footer > div.first {display: unset; width: 100%;}
	footer > div.first > .ourSocials{width: 100%; display: flex; justify-content: center;}
	footer > div.first > .ourAddress{ width: 50%; font-size: 70%; margin-top: 0%; }
	footer > div.first > .ourAddress > p{ text-indent: 5%;}
	footer > div.first > .ourContacts{width: 50%; font-size: 70%; margin-top: 0%; float: right;}
	footer > div.first > .ourContacts > p{text-indent: 5%; }
	footer > div.second {font-size: 80%; padding-bottom: 125px; }
	footer > div > div{display: inline-block; width: 100%; min-height: 100px;}
	footer > div > div > a{display: inline-block; margin: 2% 2% 2% 2%;}
	footer > div > div > a:nth-child(1){margin-left: 5%; }
	footer > div > div > a > div{width: 40px; height: 40px;}
	footer > div > div > a > div > img{width: 90%; margin: 5%; }


	

}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
	/*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
	/*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	/*iPad Pad 1, 2, Mini and Air*/
	

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
	/*iPad*/
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
	/*iPad Pro*/
}



/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (orientation: landscape) {
	
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1024px)and (device-height:1366px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad Pro*/

}

@media only screen and (device-width: 768px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad*/
	
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
	/*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
	/*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
	/*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 5 &SE*/

}



@keyframes slideInBottom {
	0% { opacity:0; -webkit-transform: translate(-50%, -30%); }
	/* 80% { opacity:0.8; -webkit-transform: translate(-50%, -30%); } */
	100% { opacity:1; -webkit-transform: translate(-50%, -50%); }
} 