.paymentSteps{ margin-top: 5%;}
.paymentSteps > .applyVoucher {width: 100%; display: flex; justify-content: center; position: relative;}
.paymentSteps > .applyVoucher:after {content: ''; position: absolute; width: 90%; height: 1px; left: 5%; top: 200%;
    background-color: silver;
}
.paymentSteps > .applyVoucher > input{width: 80%; height: 35px; border: 1px solid silver; border-radius: 5px; margin: 1%;
    font-weight: bolder; font-size: 120%;
}
.paymentSteps > .applyVoucher > button{width: 170px; height: 35px; border-radius: 5px; border: none; margin: 1%;
    text-transform: uppercase; font-weight: bolder; cursor: pointer; }
/* .paymentSteps > .applyVoucher > .voucherState{} */
.paymentSteps > .applyVoucher > .voucherVerifying{ cursor: gray; cursor: progress;}
.paymentSteps > .applyVoucher > .voucherVerified{background-color: gray;}
.paymentSteps > .applyVoucher > .voucherPaying{background-color: unset;}
.paymentSteps > .applyVoucher > .voucherPaid{background-color: limegreen;}

.paymentSteps > .otherMethods:before{content: 'OR'; position: absolute; width: 30px; height: 30px; left: 50%; top: -42.5%;
    transform: translateX(-50%); text-align: center; border-radius: 50%; background-color: white;}
.paymentSteps > .otherMethods {position: relative; width: 100%; margin-top: 15%; min-height: 100px;}
.paymentSteps > .otherMethods > button{position: absolute; left: 50%; transform: translateX(-50%); min-width: 100px; height: 35px; border-radius: 5px; border: none; 
    text-transform: uppercase; font-weight: bolder; cursor: pointer; background-color: silver;}
.paymentSteps > .otherMethods > p{position: absolute; left: 50%; transform: translateX(-50%); margin-top: 7%; color: red;}

.paymentSteps > .packageOptions{width: 100%; height: 35px;display: flex; justify-content: center;}
.paymentSteps > .packageOptions > div{width: 50%; border-radius: 25px 25px 0px 0px; position: relative; cursor: pointer;}
.paymentSteps > .packageOptions > div.active{ background: linear-gradient(to bottom, var(--main-dark-color), rgba(30, 20, 20, 0.6), rgba(30, 20, 20, 0.6));
    /*border-top: 1px solid silver; border-left: 1px solid silver; border-right: 1px solid silver;*/}
.paymentSteps > .packageOptions > div > p{margin: 4% 0% 0% 0%; height: 100%; text-align: center; pointer-events: none;}
.paymentSteps > .packageOptions > div > p > span{width: 40px; height: 40px; text-align: center; border-radius: 50%; 
    padding: 1%; /*background: var(--main-dark-color)*/;}
.paymentSteps > .packageOptions > div.active:first-child::before{content: ''; position: absolute; top: 100%; left: 0%;
    width: 100%; height: 100%; background: linear-gradient(to bottom, rgba(30, 20, 20, 0.6), transparent);
}
.paymentSteps > .packageOptions > div.active:first-child::after{content: ''; position: absolute; top: 100%; left: 100%;
    width: 100%; height: 100%; border-radius: 0px 25px 0px 0px; border-right: 1px solid silver; border-top: 1px solid silver;
    background: linear-gradient(to bottom, rgba(30, 20, 20, 0.6), transparent);
}


.paymentSteps > .packageOptions > div.active:last-child::before{content: ''; position: absolute; top: 100%; left: -100%; 
    width: 100%; height: 100%; border-radius: 25px 0px 0px 0px; border-left: 1px solid silver; border-top: 1px solid silver;
    background: linear-gradient(to bottom, rgba(30, 20, 20, 0.6), transparent);
}
.paymentSteps > .packageOptions > div.active:last-child::after{content: ''; position: absolute; top: 100%; left: 0%;
    width: 100%; height: 100%; background: linear-gradient(to bottom, rgba(30, 20, 20, 0.6), transparent);
}



@media only screen and (max-width: 600px) {
    .paymentSteps > .otherMethods > button{ min-width: 200px; }
    .paymentSteps > .otherMethods > p{margin-top: 12%; width: 98%; text-align: center;}
	

}
@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
	/*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
	/*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	/*iPad Pad 1, 2, Mini and Air*/
	

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
	/*iPad*/
}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
	/*iPad Pro*/
}



/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) and (orientation: landscape) {
	
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1024px)and (device-height:1366px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad Pro*/

}

@media only screen and (device-width: 768px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad*/
	
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
	/*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
	/*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
	/*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 5 &SE*/

}