.profileUpdatePopupBackground{
    position: fixed; z-index: 10; top: 0%; left: 0%; width: 100%; height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
}

.profileUpdateFormPopup{
    position: fixed; z-index: 11; width: 65%; top: 50%; left: 50%; transform: translate(-50%, -50%); min-height: 200px; 
    border-radius: 15px; background-color: white; box-shadow: 0px 0px 15px black; max-height: 90vh;
	opacity: 1; animation-name: slideInBottom;
	animation-iteration-count: 1; animation-timing-function: ease-in-out; animation-duration: 0.5s;
}
.profileUpdateFormPopup > h1{width: 95%; text-align: center; }
.profileUpdateFormPopup > form{width: 95%; padding: 2.5%; }
.profileUploadInput{width: 47.5%; display: inline-block; margin: 1% 0% 1% 1.75%; }
.profileUploadInput > .materialField{width: 100%; }
.profileUploadInput > .materialButton{width: 100%; }
.profilePicInput{visibility: hidden;}
/* .thePic{display: flex;} */
.profileUploadInput > .uploadPreviewSpace{width: 150px; height: 150px; border-radius: 50%; }  
.profileUploadInput > .uploadPreviewSpace > img{ border-radius: inherit; width: 100%; height: 100%; object-fit: cover;}

.uploadSubmit {width: 96.5%; }


dProgressUpdate{width: 100%; }


@media only screen and (max-width: 600px) {
    .updateFormPopup{width: 98%; }

    .uploadInput:nth-child(1){width: 98%; display: inline-block; margin: 1% 0% 1% 1.75%; }
}


@media only screen and (min-width: 600px) and (max-width: 1023px) {

}
@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 5 &SE*/

}
@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2){
	/*iPhone 6, 7, 8*/

}
@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3){
	/*iPhone 6+, 7+, 8+*/

}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) { 
	/*iPhone X*/

}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	/*iPad Pad 1, 2, Mini and Air*/
	

}

@media only screen and (device-width: 768px)and (device-height:1024px)and (-webkit-device-pixel-ratio:2){
	/*iPad*/

}

@media only screen and (device-width: 1024px)and (device-height:1366px)and (-webkit-device-pixel-ratio:2){
	/*iPad Pro*/
	
}

 @media only screen and (device-width: 1280px)and (device-height:800px)and (-webkit-device-pixel-ratio:1){
	/*laptop with mdpi screen*/
  }
  @media only screen and (device-width: 1280px)and (device-height:950px)and (-webkit-device-pixel-ratio:1){
	/*laptop with touch screen*/
  }
  
  @media only screen and (device-width: 1366px)and (device-height:768px){
	/*HP laptop screen*/
  }
  
  @media only screen and (device-width: 1440px)and (device-height:900px)and (-webkit-device-pixel-ratio:2){
	/*laptop with hidpi screen*/
 }


/*
*=============================================================================================================
*LANDSCAPE EDITION
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-height: 600px) and (orientation: landscape) {
	
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 1023px) and (orientation: landscape) {
	
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (orientation: landscape) {

}

@media only screen and (device-width: 1366px)and (device-height:1024px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad Pro*/

}

@media only screen and (device-width: 1024px)and (device-height:768px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPad*/
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
	/*iPad Pad 1, 2, Mini and Air*/

}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) { 
	/*iPhone X*/

}

@media only screen and (device-width: 414px)and (device-height: 736px) and (-webkit-device-pixel-ratio:3) and (orientation: landscape) {
	/*iPhone 6+, 7+, 8+*/

}

@media only screen and (device-width: 375px)and (device-height: 667px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 6, 7, 8*/

}

@media only screen and (device-width: 320px)and (device-height:568px) and (-webkit-device-pixel-ratio:2) and (orientation: landscape) {
	/*iPhone 5 &SE*/

}